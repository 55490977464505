import React from 'react'
import { Outlet } from 'react-router-dom'

import { Header, Sidebar, Snackbar } from '@/components'
import { useGlobalState } from '@/context'
import { Stack, Container, Drawer } from '@mui/material'

import * as S from './style'

export const LayoutDefault = () => {
  const [{ snackbar, isOpenDrawerSidebar }, dispatch] = useGlobalState()

  const handleActionDrawerSidebar = (open: boolean) => {
    dispatch({
      type: 'SET_IS_OPEN_DRAWER_SIDEBAR',
      data: open
    })
  }

  return (
    <S.Container>
      <Stack direction='row'>
        <Stack
          style={{ width: '280px' }}
          display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
        >
          <Sidebar />
        </Stack>

        <Stack direction='column' flex='1' style={{ position: 'relative' }}>
          <S.ContainerHeader
          >
            <Container maxWidth='xl'>
              <Header />
            </Container>
          </S.ContainerHeader>

          <Stack
            style={{
              paddingTop: 100,
              width: '100%',
              height: '100vh',
              overflowY: 'auto',
            }}
            sx={{ paddingBottom: { xs: 4 } }}
            display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
          >
            <Container fixed>
              <Stack paddingX={{ xs: 2, xl: 0 }}>
                <Outlet />
              </Stack>
            </Container>
          </Stack>

          <Stack
            style={{
              paddingTop: 100,
              width: '100%',
              height: '100vh',
              overflowY: 'auto',
            }}
            sx={{ paddingBottom: { xs: 4 } }}
            display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }}
          >
            <Container maxWidth='xl'>
              <Outlet />
            </Container>
          </Stack>
        </Stack>
      </Stack>

      <Drawer
        open={isOpenDrawerSidebar}
        onClose={() => { handleActionDrawerSidebar(false) }}
        PaperProps={{
          sx: {
            minWidth: 320, // '80%' 
          }
        }}
      >
        <Sidebar />
      </Drawer>

      <Snackbar
        message={snackbar?.message}
        alertSeverity={snackbar?.alertSeverity}
        anchorOrigin={snackbar?.anchorOrigin}
        // TransitionComponent={Slide}
        onCloseSnackbar={() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: undefined
          })
        }}
      />
    </S.Container >
  )
}
