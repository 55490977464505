/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TbChevronRight, TbDotsVertical, TbPencil, TbSearch, TbTrash, TbUserPlus, TbX } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { orderBy, QueryFieldFilterConstraint, where } from 'firebase/firestore';

import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes';
import * as ApiService from '@/services/api'
import { theme } from '@/styles/mui-style';
import {
    Avatar,
    Box,
    Drawer,
    FormHelperText,
    IconButton,
    ListItemButton,
    ListItemIcon,
    MenuItem,
    Stack,
    Menu,
    TextField,
    Typography,
    useMediaQuery,
    Button,
    InputAdornment,
} from '@mui/material'
import { grey, red } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { AvatarLetter } from '../avatar-letter';
import { CircularProgress } from '../circular-progress';
import { LoadingTable } from '../loadind-table';
import { NoContent } from '../no-content';

interface DrawerChooseClientProps {
    errorMessage?: string
    clientChoosed?: Client
    clientIdToSelect?: string | undefined
    isDisabled?: boolean
    setClientChoosed: (client: Client | undefined) => void
}

export const DrawerChooseClient = ({ clientChoosed, clientIdToSelect, errorMessage, isDisabled, setClientChoosed }: DrawerChooseClientProps) => {
    const navigate = useNavigate()
    const [{ company }] = useGlobalState()
    const [clientList, setClientList] = useState<Client[] | undefined>(undefined)
    const [loadingList, setLoadingList] = useState<boolean>(false)
    const [loadingClient, setLoadingClient] = useState<boolean>(false)
    const [searchClient, setSearchClient] = useState<string>('')
    const [showDrawerChooseClient, setShowDrawerChooseClient] = useState<boolean>(false)
    const companyId = useMemo(() => company?.id || '', [])

    const isXs = useMediaQuery(theme.breakpoints.only("xs"));

    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
    const open = Boolean(anchorEl)

    const hasSearchClient = useMemo(() => {
        if (searchClient && searchClient.length > 0) return true
        return false
    }, [searchClient])

    const handleCloseMenuAction = () => {
        setAnchorEl(undefined)
    }

    const handleMenuAction = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const getClient = (clientName: string) => {
        const whereList: QueryFieldFilterConstraint[] = [where('fullnameSearch', '>=', clientName.toUpperCase()), where('fullnameSearch', '<=', clientName.toUpperCase() + `\uf8ff`)]
        const orderByList = [orderBy('fullnameSearch', 'asc')]

        ApiService.Client.getList({
            companyId,
            setClientList,
            setLoadingList,
            whereList,
            orderByList
        })
    }

    const getSilgleClient = async () => {
        if (!clientIdToSelect) return
        setLoadingClient(true)
        ApiService.Client.getOne({
            companyId,
            clientId: clientIdToSelect,
        }).then((client) => {
            setClientChoosed(client)
            setLoadingClient(false)
        })
    }

    const handleCloseDrawer = () => {
        setShowDrawerChooseClient(false)
    }

    useEffect(() => {
        getSilgleClient()
    }, [])

    return (
        <Box>
            <Stack display='flex' flexDirection='row'
                sx={{
                    height: 54,
                    width: '100%',
                    borderRadius: 1,
                    border: `1px solid ${errorMessage ? red[700] : grey[200]}`,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 1.5,

                }}>
                {loadingClient && (
                    <CircularProgress />
                )}
                {!loadingClient && (
                    <Stack display='flex' flex={1} alignItems='center' flexDirection='row' flexWrap='nowrap'>
                        {clientChoosed
                            ? (
                                <>
                                    {clientChoosed?.photoURL
                                        ? <Avatar sx={{ height: 40, width: 40 }} src={clientChoosed.photoURL} />
                                        : <AvatarLetter name={(clientChoosed?.fullname || '').substring(0, 1).toUpperCase()} />}

                                    <Typography
                                        variant='body2'
                                        textOverflow='ellipsis'
                                        noWrap
                                        width={150}
                                        flex={1}
                                        // display='flex'
                                        // overflow='hidden'
                                        // minWidth={0}
                                        // whiteSpace='nowrap'
                                        // flexShrink={0}
                                        ml={1}
                                    >
                                        {clientChoosed.fullname}
                                    </Typography>
                                </>
                            )
                            :
                            (
                                <Typography variant='body2' flex={1}
                                    color={errorMessage ? red[700] : 'inherit'}
                                >
                                    Select a client
                                </Typography>
                            )
                        }

                        {clientChoosed ? (
                            <IconButton sx={{ height: 40, width: 40 }} onClick={handleMenuAction}>
                                <TbDotsVertical color={grey[600]} />
                            </IconButton>
                        )
                            :
                            (
                                <IconButton
                                    disabled={isDisabled}
                                    sx={{ height: 40, width: 40 }}
                                    onClick={() => {
                                        setShowDrawerChooseClient(true)
                                    }}
                                >
                                    <TbChevronRight color={grey[isDisabled ? 400 : 600]} />
                                </IconButton>
                            )
                        }
                    </Stack>
                )}
            </Stack>

            {errorMessage && (
                <FormHelperText sx={{ pl: 1.8, color: red[700] }}>{errorMessage}</FormHelperText>
            )}

            <Drawer
                open={showDrawerChooseClient}
                anchor='right'
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: { width: isXs ? '100%' : 600 }
                }}

            >
                <Stack display='flex' flex={1}>
                    <Stack position='absolute' right='12px' top='12px'>
                        <IconButton onClick={handleCloseDrawer}>
                            <TbX color='#fff' />
                        </IconButton>
                    </Stack>
                    <Stack paddingBottom={2} marginBottom={1}
                        sx={{ backgroundColor: grey[900], padding: 2 }}
                    >
                        <Typography variant='h6' color={'#fff'}>
                            Select a client
                        </Typography>
                    </Stack>
                    <Stack padding={2}>
                        <Stack display='flex' flexDirection='row'>
                            <Stack display='flex' flexDirection='row' flex={1} >
                                <TextField
                                    label="Search client"
                                    fullWidth
                                    value={searchClient}
                                    onChange={(event) => { setSearchClient(event.target.value); }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        setSearchClient('')
                                                        setClientList(undefined)
                                                    }}
                                                    disabled={!hasSearchClient}
                                                >
                                                    <TbX color={grey[hasSearchClient ? 900 : 400]} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <IconButton
                                    size='large'
                                    onClick={() => {
                                        getClient(searchClient)
                                    }}
                                    disabled={!hasSearchClient}
                                >
                                    <TbSearch color={grey[hasSearchClient ? 900 : 400]} />
                                </IconButton>
                            </Stack>

                            <Button
                                size='small'
                                startIcon={<TbUserPlus />}
                                color='btnSecondary'
                                variant='contained'
                                onClick={() => {
                                    navigate(RoutesNames.CLIENT_CRUD)
                                }}
                                disableElevation
                                sx={{ ml: isXs ? 1 : 5 }}
                            >
                                {isXs ? 'New' : 'New client'}

                            </Button>
                        </Stack>

                        <Stack mt={5}>
                            <Typography variant='h6' >
                                Result of filter
                            </Typography>
                            {loadingList && (
                                <LoadingTable height={300} position='relative' />
                            )}
                            {!loadingList && clientList && clientList?.length > 0 && (
                                <List sx={{ width: '100%', paddingLeft: 1 }}>
                                    {clientList?.map((client) => (
                                        <ListItemButton
                                            disableGutters
                                            key={client.id}
                                            selected={clientChoosed ? clientChoosed.id === client.id : undefined}
                                            sx={{ borderRadius: 2 }}
                                            onClick={() => {
                                                setClientChoosed(client)
                                                setShowDrawerChooseClient(false)
                                            }}
                                        >
                                            <ListItemAvatar>
                                                {client?.photoURL
                                                    ? <Avatar src={client.photoURL} />
                                                    : <AvatarLetter name={(client.fullname || '').substring(0, 1).toUpperCase()} />
                                                }
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={client.fullname}
                                                secondary={`E-mail: ${client.email} - Phone: ${client.phoneNumber}`}
                                            />
                                        </ListItemButton>
                                    ))}
                                </List>
                            )}
                            {!loadingList && clientList && clientList?.length === 0 && (
                                <NoContent
                                    size={150}
                                    subtitle='No results for the filter'
                                />
                            )}
                            {!loadingList && !clientList && (
                                <NoContent
                                    type='NO_SEARCH_YET'
                                    size={150}
                                    subtitle='No clients searched'
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </Drawer>

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenuAction}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    disabled={isDisabled}
                    onClick={() => {
                        handleCloseMenuAction()
                        setShowDrawerChooseClient(true)
                    }}>
                    <ListItemIcon>
                        <TbPencil size={20} color={grey[900]} />
                    </ListItemIcon>
                    <Typography variant='body2'>Edit</Typography>
                </MenuItem>
                <MenuItem
                    disabled={isDisabled}
                    onClick={() => {
                        handleCloseMenuAction()
                        setClientChoosed(undefined)
                    }}>
                    <ListItemIcon>
                        <TbTrash size={20} color={red[500]} />
                    </ListItemIcon>
                    <Typography variant='body2' color={red[500]}>
                        Remove client
                    </Typography>
                </MenuItem>
            </Menu>

        </Box >
    )
}
