import React, { useEffect, useMemo } from 'react'
import {
  TbApps,
  TbChevronRight,
  TbChevronDown,
  TbUsers,
  TbTargetArrow,
  TbAward,
  TbSettings2,
  TbPointFilled,
  TbHome,
  TbCoinPound,
  TbSettings,
  TbPackageImport,
} from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { grey } from '@mui/material/colors'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { AvatarLetter } from '../avatar-letter'
import * as S from './style'

const ICON_SIZE_MENU = 24
const ICON_SIZE_SUBMENU_ACTIVE = 12
const ICON_SIZE_SUBMENU_INACTIVE = 12

const SidebarConfigCommon: MenuItem[] = [
  {
    id: RoutesNames.HOME,
    title: 'Dashboard',
    iconStart: <TbApps size={ICON_SIZE_MENU} />,
    path: RoutesNames.HOME,
  },
]

export const SidebarConfigRoot: Menu[] = [
  {
    id: 'Management',
    title: 'Management',
    menuList: [
      ...SidebarConfigCommon,
      {
        id: RoutesNames.USER,
        title: 'Users',
        iconStart: <TbUsers size={ICON_SIZE_MENU} />,
        path: RoutesNames.USER,
      },
      {
        id: RoutesNames.COMPANY,
        title: 'Company',
        iconStart: <TbHome size={ICON_SIZE_MENU} />,
        path: RoutesNames.COMPANY,
      },
    ],
  },
]

export const SidebarConfigManger: Menu[] = [
  {
    id: 'General',
    title: 'General',
    menuList: [
      ...SidebarConfigCommon,
      {
        id: RoutesNames.CLIENT,
        title: 'Clients',
        iconStart: <TbTargetArrow size={ICON_SIZE_MENU} />,
        path: RoutesNames.CLIENT,
      },
      {
        id: RoutesNames.SERVICE,
        title: 'Services',
        iconStart: <TbAward size={ICON_SIZE_MENU} />,
        submenuList: [
          {
            id: RoutesNames.SERVICE_LIST,
            title: 'List',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.SERVICE_LIST,
          },
          {
            id: RoutesNames.SERVICE_BUCKET,
            title: 'Mobile Bucket',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.SERVICE_BUCKET,
          },
          {
            id: RoutesNames.SERVICE_HYDRAULIC,
            title: 'Mobile Hydraulic',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.SERVICE_HYDRAULIC,
          },
        ],
      },
      {
        id: RoutesNames.ORDER,
        title: 'Orders',
        iconStart: <TbCoinPound size={ICON_SIZE_MENU} />,
        submenuList: [
          {
            id: RoutesNames.ORDER_QUOTATION,
            title: 'Quotations',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.ORDER_QUOTATION,
          },
          {
            id: RoutesNames.ORDER_SALE,
            title: 'Sales',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.ORDER_SALE,
          },
          {
            id: RoutesNames.ORDER_INVOICE_CREDIT,
            title: 'Invoices & Credits',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.ORDER_INVOICE_CREDIT,
          },
          {
            id: RoutesNames.ORDER_CREDIT_NOTE,
            title: 'Credits Notes',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.ORDER_CREDIT_NOTE,
          },
        ],
      },
      {
        id: RoutesNames.STOCK,
        title: 'Stock',
        iconStart: <TbSettings2 size={ICON_SIZE_MENU} />,
        submenuList: [
          {
            id: RoutesNames.STOCK_LIST,
            title: 'List',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.STOCK_LIST,
          },
          {
            id: RoutesNames.STOCK_REQUEST,
            title: 'Purchase order',
            iconStartSize: size => <TbPointFilled size={size} />,
            path: RoutesNames.STOCK_REQUEST,
          },
        ],
      },
      {
        id: RoutesNames.SUPPLIER,
        title: 'Supplier',
        iconStart: <TbPackageImport size={ICON_SIZE_MENU} />,
        path: RoutesNames.SUPPLIER,
      },
    ],
  },
  {
    id: 'Application',
    title: 'Application',
    menuList: [
      {
        id: RoutesNames.CONFIG,
        title: 'Config company',
        iconStart: <TbSettings size={ICON_SIZE_MENU} />,
        path: RoutesNames.CONFIG,
      },
    ],
  },
]

interface MenuSelected {
  menuId: string
  submenuId?: string
  showSubmenuId?: string
}


const UserLogged = () => {
  const [{ user, company }] = useGlobalState()

  if (user?.userConfig?.roles.includes('ROOT')) return null

  return (
    <Stack
      display='flex'
      width='100%'
      flexDirection='row'
      padding={2}
      alignItems='center'
      sx={{
        backgroundColor: 'white',
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
        border: `1px solid ${grey[300]}`
      }}
    >
      {company?.photoURL ? (
        <Avatar src={company?.photoURL}
          sx={{ width: 44, height: 44, border: `1px solid ${grey[200]}` }}
        />
      ) : (
        <AvatarLetter
          name={(company?.name || '').substring(0, 1).toUpperCase()}
        />
      )}
      <Box mr={2} />
      <Stack display='flex' justifyContent='center'>
        <Typography
          variant='subtitle2'
          color='text.primary'
          textOverflow='ellipsis'
          noWrap
          width={150}
        >
          {(company?.name || '').split(user?.displayName ? ' ' : '@')[0]}
        </Typography>
        <Typography
          variant='caption'
          color='text.primary'
          textOverflow='ellipsis'
          noWrap
          width={150}
        >
          {company?.email}
        </Typography>
      </Stack>
    </Stack>
  )
}

const Nav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ user }, dispatch] = useGlobalState()
  const [menuSelected, setMenuSelected] = React.useState<MenuSelected>(
    {} as MenuSelected,
  )
  const hasRootRole = useMemo(() => {
    const hasRole = user?.userConfig?.roles.find(role => role === 'ROOT')
    return !!hasRole
  }, [user?.userConfig?.roles])

  const handleSelectMenu = (path: string) => {
    dispatch({
      type: 'SET_IS_OPEN_DRAWER_SIDEBAR',
      data: false,
    })
    navigate(path)
  }

  const renderMenuItemExpand = (menuId: string, menuList: MenuItem[]) => (
    <Collapse
      in={menuId === menuSelected.showSubmenuId}
      timeout='auto'
      unmountOnExit
    >
      <S.ContainerMenu>
        {menuList.map(item => renderSubmenuItem(item))}
      </S.ContainerMenu>
    </Collapse>
  )

  const renderMenuItem = (menuItem: MenuItem) => {
    const isSelected = menuItem.id === menuSelected.menuId

    return (
      <>
        <S.Menu
          key={menuItem.id}
          isSelected={isSelected}
          onClick={() => {
            if (menuItem.path) {
              handleSelectMenu(menuItem.path)
            } else {
              setMenuSelected({
                ...menuSelected,
                showSubmenuId:
                  menuSelected.showSubmenuId === menuItem.id
                    ? undefined
                    : menuItem.id,
              })
            }
          }}
        >
          <div>
            {menuItem.iconStart}
            <Box mr={2} />
            <Typography
              variant={isSelected ? 'subtitle2' : 'body2'}
              color='text.secondary'
            >
              {menuItem.title}
            </Typography>
          </div>
          {menuItem.submenuList && (
            <>
              {menuItem.id === menuSelected.showSubmenuId ? (
                <TbChevronDown size={16} />
              ) : (
                <TbChevronRight size={16} />
              )}
            </>
          )}
        </S.Menu>
        {menuItem.submenuList &&
          renderMenuItemExpand(menuItem.id, menuItem.submenuList)}
      </>
    )
  }

  const renderSubmenuItem = (menuItem: MenuItem) => {
    const isActive = menuItem.id === menuSelected?.submenuId
    const spaceStart = isActive ? 0.8 : 0.8
    const spaceEnd = isActive ? 2.8 : 2.8

    return (
      <S.Submenu
        key={menuItem.id}
        isSelected={menuItem.id === menuSelected?.submenuId}
        onClick={() => {
          handleSelectMenu(menuItem.path || '')
        }}
      >
        <Box pl={spaceStart}>
          {menuItem.iconStartSize?.(
            menuItem.id === menuSelected?.submenuId
              ? ICON_SIZE_SUBMENU_ACTIVE
              : ICON_SIZE_SUBMENU_INACTIVE,
          )}

          <Box mr={spaceEnd} />
          <Typography variant='body2' color='text.secondary'>
            {menuItem.title}
          </Typography>
        </Box>
      </S.Submenu>
    )
  }

  const renderMenu = (menu: Menu) => (
    <>
      <S.ContainerList>
        <Box pl={2.3}>
          <Typography
            sx={{ fontWeight: '500', fontSize: 11 }}
            variant='caption'
          >
            {menu.title}
          </Typography>
        </Box>
        <Box mb={0.5} />
        <S.ContainerMenu>
          {menu.menuList.map(item => renderMenuItem(item))}
        </S.ContainerMenu>
      </S.ContainerList>
      <Box mb={3} />
    </>
  )

  useEffect(() => {
    const { pathname } = location
    const urlPath = pathname.substring(1).split('/')

    if (urlPath[0] && urlPath[1]) {
      setMenuSelected({
        menuId: `/${urlPath[0]}`,
        submenuId: pathname,
        showSubmenuId: `/${urlPath[0]}`,
      })
    } else {
      setMenuSelected({
        menuId: pathname,
        submenuId: undefined,
        showSubmenuId: undefined,
      })
    }
  }, [location])

  return (
    <>
      {(hasRootRole ? SidebarConfigRoot : SidebarConfigManger).map(item =>
        renderMenu(item),
      )}
    </>
  )
}

export const Sidebar = () => (
  <S.Container>
    <S.Scroll>
      <Box mb={4} />
      <UserLogged />
      <Box mb={4} />
      <Stack sx={{ pl: 2 }}>
        <Nav />
      </Stack>
    </S.Scroll>
  </S.Container>
)
