/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { orderBy } from 'firebase/firestore';
import { Form, FormikErrors, FormikHelpers, FormikProvider, FormikTouched, useFormik, FieldArray } from 'formik'
import moment from 'moment'

import { BoxShadow, Breadcrumb, FormikTextField, DialogConfirm, FormikTextNumericFormat, PhotoUpload, FormikSwitch, CircularProgress, DrawerChooseStock } from '@/components'
import { DrawerChooseStockRequest } from '@/components/drawer-choose-stock-request';
import { FormikSelectField, FormikSelectFieldMenu } from '@/components/formik-select-field';
import { EnumStockRequestStatus } from '@/constants/stock-request';
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472

interface FormikAutocompleteFieldMenu {
  id: string
  label: string
}

interface InitialValues extends Omit<StockRequest, 'id' | 'createdAt' | 'updatedAt' | 'dateCreatedAt' | 'supplierNameSearch' | 'titleSearch' | 'partsStatusSearch'> {
  keyUnique: string
  canValidateStockRequestList?: boolean
  canValidateSupplierId?: boolean
}

const INITIAL_VALUES: InitialValues = {
  title: '',
  supplierId: '',
  supplierName: '',
  isPaidOut: false,
  keyUnique: '',
  paymentCreatedAt: undefined,
  paymentDue: undefined,
  invoiceNumber: undefined,
  adviceNumber: undefined,
  stockRequestList: [],
  canValidateStockRequestList: false,
  canValidateSupplierId: false,
}

// const stockRequestStatusList: FormikSelectFieldMenu[] = Object.entries(EnumStockRequestStatus).map(([key, value]) => ({
//   value: key,
//   title: value,
// }))

interface AutocompleteSupplierProps {
  supplierList: FormikAutocompleteFieldMenu[]
  formikHelpers: Pick<FormikHelpers<Partial<StockRequest>>, 'setFieldValue' | 'setFieldTouched'>
  formikErrors: FormikErrors<Partial<StockRequest>>
  formikTouched: FormikTouched<Partial<StockRequest>>
  supplier: FormikAutocompleteFieldMenu | null
  setSupplier: React.Dispatch<React.SetStateAction<FormikAutocompleteFieldMenu | null>>
}
const AutocompleteSupplier = ({ supplierList, formikHelpers, formikErrors, formikTouched, supplier, setSupplier }: AutocompleteSupplierProps) => {
  const { setFieldValue, setFieldTouched } = formikHelpers

  const hasError = useMemo(() => formikTouched.supplierId && Boolean(formikErrors.supplierId), [formikTouched.supplierId, formikErrors.supplierId])

  return (
    <Autocomplete
      disablePortal
      value={supplier}
      options={supplierList}
      onChange={(_, data) => {
        setFieldValue('canValidateSupplierId', true)
        if (!data) {
          setSupplier(null)
          setFieldValue('supplierId', '')
          setFieldValue('supplierName', '')
          setFieldTouched('supplierId', true)
          return
        }
        setSupplier(data)
        setFieldValue('supplierId', data.id)
        setFieldValue('supplierName', data.label)
      }}
      getOptionLabel={(option) => option.label || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select supplier"
          error={hasError}
          helperText={hasError ? formikErrors?.supplierId : undefined}
        />
      )}
    />
  )
}

export const StockRequestCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const locationStatestockRequestSelected = location.state?.stockRequestSelected ? JSON.parse(location.state?.stockRequestSelected) : undefined
  const [stockRequestSelected] = useState<StockRequest | undefined>(locationStatestockRequestSelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [supplierList, setSupplierList] = useState<FormikAutocompleteFieldMenu[]>([])
  const [loadingSupplierList, setLoadingSupplierList] = useState<boolean>(false)
  const [supplierPrevSelected, setSupplierPrevSelected] = React.useState<FormikAutocompleteFieldMenu | null>(null);

  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'stockRequest',
      title: 'Purchase order',
      href: RoutesNames.STOCK_REQUEST,
    },
    {
      id: 'stock-request-crud',
      title: stockRequestSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]

  const resetForm = () => {
    formik.resetForm()
    setInitialValues(INITIAL_VALUES)
    setSupplierPrevSelected(null)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      const stockToIncrementQuantity: string[] = []
      const partsStatusSearch: string[] = []

      const stockRequestList = values.stockRequestList?.map((item) => {

        const isStockUpdated = !!(item.quantityRecived && item.advice)
        let status: StockRequestStatus = item.status

        if (!item.isStockUpdated && isStockUpdated) {
          stockToIncrementQuantity.push(item.part.id)
          status = (item.quantityRecived || 0) < item.quantityRequest ? 'DELIVERED_INCOMPLETE' : 'DELIVERED_FULL'
        }
        partsStatusSearch.push(status)

        return ({
          ...item,
          isStockUpdated: item.isStockUpdated === true ? true : isStockUpdated,
          ...(isStockUpdated ? { status } : {})
        })
      }
      )

      const { title, supplierId, supplierName, isPaidOut, paymentDue, invoiceNumber, adviceNumber } = values

      const data = {
        title,
        supplierId,
        supplierName,
        isPaidOut,
        paymentDue,
        invoiceNumber: invoiceNumber || '',
        adviceNumber: adviceNumber || '',
        stockRequestList,
        ...(!stockRequestSelected?.isPaidOut && values.isPaidOut ? { paymentCreatedAt: new Date().getTime() } : {}),
        partsStatusSearch,
      }

      if (!stockRequestSelected) {
        await ApiService.StockRequest.save({
          companyId,
          values: data
        }).then(() => {
          resetForm()
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
        }).catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on save', alertSeverity: 'error' }
          })
        }).finally(() => {
          setLoadingSave(false)
        })
        return
      }

      await ApiService.StockRequest.update({
        companyId,
        stockRequestId: stockRequestSelected?.id,
        values: data
      }).then(async () => {
        let isUpdateStock = false

        stockRequestList?.forEach(async (item) => {
          if (!stockToIncrementQuantity.includes(item.part.id)) return

          const stock = await ApiService.Stock.getOne({ companyId, stockId: item.part.id })
          if (!stock) {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: `The part "${item.part.title}" is no longer in stock! Process closed`, alertSeverity: 'error' }
            })
            return
          }

          const sumQuantity = Number((stock.quantity || 0)) + Number((item.quantityRecived || 0))

          await ApiService.Stock.update({
            companyId,
            stockId: item.part.id,
            values: { quantity: sumQuantity, price: (item.priceSale || 0) },
            isFullUpdate: false,
          }).then(() => {
            isUpdateStock = true
          }).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error on update', alertSeverity: 'error' }
            })
          }).finally(() => {
            setLoadingSave(false)
          })
        })


        resetForm()
        navigate(RoutesNames.STOCK_REQUEST)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: isUpdateStock ? 'Stock incremented successful' : 'Save successfull' }
        })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingSave(false)
      })
    },
  })

  const handleDeleteStock = async () => {
    if (!stockRequestSelected) return
    setLoadingDelete(true)
    await ApiService.StockRequest.remove({ companyId, stockRequestId: stockRequestSelected.id })
      .then(async () => {
        resetForm()
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request deleted' }
        })
        navigate(RoutesNames.STOCK_REQUEST)
      }).catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request was not deleted' }
        })
      }).finally(() => {
        setLoadingDelete(false)
      })
  }

  const getSupplierList = async () => {
    await ApiService.Supplier.getList({
      companyId,
      setSupplierList: (list) => {
        if (!list) return
        const resp = list.map((item) => ({
          id: item.id,
          label: item.name,
        }))
        setSupplierList(resp as FormikAutocompleteFieldMenu[])
      },
      setLoadingList: setLoadingSupplierList,
      orderByList: [orderBy('name', 'asc')],
    })
  }

  // const errorMessagePaymentDue = (formikErrors: FormikErrors<InitialValues>, formikTouched: FormikTouched<InitialValues>, idx: number): string => {

  //   if (Array.isArray(formikErrors.stockRequestList)) {
  //     if (formikErrors.stockRequestList[idx]) {
  //       if (formikErrors.stockRequestList[idx].paymentDue) {
  //         if (Array.isArray(formikTouched.stockRequestList)) {
  //           if (formikTouched.stockRequestList[idx]) {
  //             if (formikTouched.stockRequestList[idx].paymentDue) {
  //               return formikErrors.stockRequestList[idx].paymentDue
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return ''
  // }

  const errorMessageStockRequestList = useMemo(() => {
    if (!Array.isArray(formik.errors.stockRequestList)) {
      return formik.errors.stockRequestList
    }
    return ''
  }, [formik.errors.stockRequestList])

  useEffect(() => {
    if (supplierList.length === 0) {
      getSupplierList()
      return
    }

    if (!stockRequestSelected) return

    const supplierItem = supplierList.find((item) => item.id === stockRequestSelected.supplierId)

    setSupplierPrevSelected(supplierItem || null)

    if (!supplierItem) return

    const { id, title, supplierId, supplierName, isPaidOut, paymentDue, invoiceNumber, adviceNumber, stockRequestList } = stockRequestSelected
    // const stockRequestListJson = JSON.parse(stockRequestSelected.stockRequestList as unknown as string) as StockRequestListItem[]

    setInitialValues({
      title,
      supplierId,
      supplierName,
      isPaidOut,
      paymentDue,
      invoiceNumber,
      adviceNumber,
      stockRequestList,
      keyUnique: id.substring(0, 6).toUpperCase(),
    })

  }, [stockRequestSelected, supplierList])

  useEffect(() => {
    if (!formik.values.canValidateStockRequestList && !formik.values.canValidateSupplierId) return
    formik.validateForm()
  }, [
    formik.values.stockRequestList,
    formik.values.canValidateSupplierId,
    formik.values.canValidateStockRequestList,
    formik.values.supplierId
  ])

  return (
    <Box>
      <Breadcrumb
        pageTitle={
          stockRequestSelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik} >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      isDisableUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                    />
                  </Stack>

                  <FormikSwitch
                    size='small'
                    name='isPaidOut'
                    label={formik.values.isPaidOut ? 'Paid out' : 'Not paid out'}
                    // disabled={!stockRequestSelected || !formik.values.invoiceNumber}
                    onChange={(event) => {
                      if (stockRequestSelected?.isPaidOut) {
                        dispatch({
                          type: 'SET_SNACKBAR',
                          data: { message: 'This field cannot be changed anymore', alertSeverity: 'warning' }
                        })
                        formik.setFieldValue('isPaidOut', true)
                        return
                      }
                      // if (!formik.values.invoiceNumber) {
                      //   dispatch({
                      //     type: 'SET_SNACKBAR',
                      //     data: { message: 'To enable payment status, you must enter invoice number', alertSeverity: 'warning' }
                      //   })
                      //   formik.setFieldValue('isPaidOut', false)
                      //   return
                      // }

                      formik.setFieldValue('isPaidOut', event.target.checked)
                    }}
                  />

                  {stockRequestSelected && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        disabled={stockRequestSelected.isPaidOut}
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>
                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={8}>
                          <FormikTextField name='title' label='Title request' />
                        </Grid>
                        <Grid item xs={4}>
                          <FormikTextField
                            name='keyUnique'
                            label={stockRequestSelected ? 'Key unique' : 'Key unique soon...'}
                            placeholder=''
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {!loadingSupplierList && (
                            <AutocompleteSupplier
                              supplierList={supplierList}
                              formikHelpers={{ setFieldValue: formik.setFieldValue, setFieldTouched: formik.setFieldTouched }}
                              formikErrors={formik.errors}
                              formikTouched={formik.touched}
                              supplier={supplierPrevSelected}
                              setSupplier={setSupplierPrevSelected}
                            />
                          )}
                          {loadingSupplierList && (
                            <Stack width='100%' justifyContent='center' alignItems='center' border={`1px solid ${grey[200]}`} height={54} borderRadius={1}>
                              <CircularProgress />
                            </Stack>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Stack sx={{
                            display: 'flex', flexDirection: 'column', width: '100%'
                          }}>
                            <DrawerChooseStockRequest
                              stockListChoosed={(formik.values.stockRequestList || [])}
                              setstockListChoosed={list => {
                                formik.setFieldValue('canValidateStockRequestList', true)
                                if (list.length > 0) {
                                  formik.setFieldValue('stockRequestList', list)
                                } else {
                                  formik.setFieldValue('stockRequestList', [])
                                }
                              }}
                              reset={() => {
                                formik.setFieldValue('stockRequestList', (formik.values.stockRequestList || []).filter((item) => item.isStockUpdated))
                              }}
                              errorMessage={errorMessageStockRequestList}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            name='invoiceNumber'
                            label='Invoice number' />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            name='adviceNumber'
                            label='Advice number' />
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl fullWidth>
                              <DatePicker
                                slotProps={{
                                  textField: {
                                    error: formik.touched.paymentDue && Boolean(formik.errors.paymentDue),
                                    helperText: (formik.touched.paymentDue && Boolean(formik.errors.paymentDue)) ? formik.errors.paymentDue?.toString() || '' : ''
                                  },
                                  field: {
                                    clearable: true,
                                    onClear: () => { formik.setFieldValue('paymentDue', null) },
                                  },
                                }}
                                format='DD/MM/YYYY'
                                label="Payment due (today + 30d)"
                                minDate={moment()}
                                name='paymentDue'
                                value={formik.values.paymentDue ? moment(formik.values.paymentDue) : null}
                                onChange={(date) => {
                                  if (!date) {
                                    formik.setFieldValue('paymentDue', null, false)
                                    formik.setFieldTouched('paymentDue', true)
                                  } else {
                                    formik.setFieldValue('paymentDue', date.valueOf())
                                  }
                                }}
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </Grid>
                        {(formik.values.stockRequestList || []).length > 0 && (
                          <Grid item xs={12}>
                            <Stack>
                              <Typography variant='subtitle1' fontWeight={500} mb={1} mt={-1}>List of parts</Typography>
                            </Stack>
                            <FieldArray
                              name='stockRequestList'
                              render={(arrayProps) => (
                                <Form>
                                  <Stack>
                                    {formik.values.stockRequestList?.map((item, idx: number) => {
                                      const disableFields = !!item.isStockUpdated
                                      // const errorMsgPaymentDue = errorMessagePaymentDue(formik.errors, formik.touched, idx)
                                      return (
                                        <Stack key={item.part.id}
                                          sx={{
                                            ...(idx < (formik.values.stockRequestList || [])?.length - 1
                                              ? { pb: 3, mb: 2, borderBottom: `1px dashed ${grey[900]}` }
                                              : undefined),
                                            display: 'flex', flexDirection: 'row',
                                          }}
                                        >
                                          <Stack sx={{ display: 'flex', justifyContent: 'center', width: 40 }}>
                                            <Typography variant='h6' fontWeight={500}>{idx + 1}</Typography>
                                          </Stack>
                                          <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant='caption' mb={1.5} fontWeight={500} >
                                              Name: {item.part.title}
                                            </Typography>
                                            <Grid container spacing={1} rowSpacing={2.5}>
                                              <Grid item xs={2}>
                                                <FormikTextField
                                                  disabled={disableFields}
                                                  size='small'
                                                  name={`stockRequestList[${idx}].quantityRequest`}
                                                  label='Qtd req.'
                                                  type='number'
                                                />
                                              </Grid>
                                              <Grid item xs={2}>
                                                <FormikTextField
                                                  disabled={disableFields || !stockRequestSelected}
                                                  size='small'
                                                  name={`stockRequestList[${idx}].quantityRecived`}
                                                  label='Qtd rec.'
                                                  type='number'
                                                />
                                              </Grid>
                                              <Grid item xs={3}>
                                                <FormikTextNumericFormat
                                                  disabled={disableFields}
                                                  size='small'
                                                  name={`stockRequestList[${idx}].priceBuy`}
                                                  label='Price buy' />
                                              </Grid>
                                              <Grid item xs={3}>
                                                <FormikTextNumericFormat
                                                  disabled={disableFields}
                                                  size='small'
                                                  name={`stockRequestList[${idx}].priceSale`}
                                                  label='Price sale' />
                                              </Grid>
                                              <Grid item xs={2}>
                                                <FormikTextField
                                                  disabled={disableFields || !stockRequestSelected}
                                                  size='small'
                                                  name={`stockRequestList[${idx}].advice`}
                                                  label='Advice' />
                                              </Grid>
                                              <Grid item xs={5} />
                                            </Grid>
                                          </Stack>
                                        </Stack>
                                      )
                                    })}
                                  </Stack>
                                </Form>
                              )
                              }
                            >
                            </FieldArray>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Stack>
                  <Stack flexDirection='row' justifyContent='flex-end' mt={5} >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => { navigate(RoutesNames.STOCK_REQUEST) }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                      disabled={loadingSupplierList || stockRequestSelected?.isPaidOut}
                    >
                      {stockRequestSelected ? 'Save update' : 'Save request'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider >

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${stockRequestSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteStock() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />
    </Box >
  )
}
