/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TbTrash } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'

import { Form, FormikProvider, useFormik } from 'formik'

import {
  BoxShadow,
  Breadcrumb,
  FormikTextField,
  FormikSelectField,
  DialogConfirm,
  PhotoUpload,
  FormikSwitch,
  DrawerChooseClient,
  CircularProgress,
} from '@/components'
import { FormikSelectFieldMenu } from '@/components/formik-select-field'
import { EnumServiceStatus, EnumServiceType } from '@/constants/service'
import { useGlobalState } from '@/context'
import { useStorageFiles } from '@/hooks/useStorageFiles'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { theme } from '@/styles/mui-style'
import { getPathFileCompanyService } from '@/utils/storage'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'

import { validationSchema } from './validationSchema'

const BOX_HEIGHT = 472
const PHOTO_NAME = 'service_image'

interface InitialValues
  extends Omit<Service, 'id' | 'createdAt' | 'updatedAt' | 'clientFullname' | 'dateCreatedAt'> {
  hasImage?: boolean
  keyUnique?: string
}

const INITIAL_VALUES: InitialValues = {
  title: '',
  description: '',
  isActive: true,
  keyUnique: '',
  po: '',
  status: 'TO_DO',
  clientId: '',
  typeService: 'HYDRAULIC',
  hasImage: false,
}

export const ServiceCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const btnSaveServiceRef = useRef<any>(null)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const locationStateServiceSelected = location.state?.serviceSelected
    ? JSON.parse(location.state?.serviceSelected)
    : undefined
  const [serviceSelected, setServiceSelected] = useState<Service | undefined>(
    locationStateServiceSelected,
  )
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const { startUpload } = useStorageFiles()
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Services',
      title: 'Services',
      href: RoutesNames.SERVICE_LIST,
    },
    {
      id: 'service-crud',
      title: serviceSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [fileFromStorageSelecet, setFileFromStorageSelecet] = useState<
    FileFromStorage | undefined
  >(undefined)
  const [fileListToUpload, setFileListToUpload] = useState<File[]>([])
  const [FileFromStorageList, setFileFromStorageList] = useState<
    FileFromStorage[] | undefined
  >(undefined)
  const [statusList, setStatusList] = useState<FormikSelectFieldMenu[]>([])
  const [typeServiceList, setTypeServiceList] = useState<
    FormikSelectFieldMenu[]
  >([])
  const [clientChoosed, setClientChoosed] = useState<Client | undefined>(
    undefined,
  )

  const resetForm = () => {
    formik.resetForm()
    setInitialValues(INITIAL_VALUES)
    setFileToUpload(undefined)
    setFileListToUpload([])
    setServiceSelected(undefined)
    setClientChoosed(undefined)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      setLoadingSave(true)

      delete values.hasImage

      const data = {
        ...values,
        clientFullname: clientChoosed?.fullname || '',
      }
      delete data.keyUnique

      if (!serviceSelected) {
        const serviceId = await ApiService.Service.save({
          companyId,
          values: data,
        })

        if (fileListToUpload.length > 0) {
          startUpload({
            files: fileListToUpload,
            filePath: getPathFileCompanyService(companyId, serviceId),
            fileName: PHOTO_NAME,
            onSuccess(urls) {
              setLoadingSave(false)
              resetForm()
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Save successfull' },
              })
            },
            onError() {
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Error saving image' },
              })
              setLoadingSave(false)
            },
          })
        } else {
          resetForm()
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' },
          })
          setLoadingSave(false)
        }

        return
      }

      await ApiService.Service.update({
        companyId,
        serviceId: serviceSelected.id,
        values: data,
      })
        .then(async () => {
          resetForm()
          navigate(RoutesNames.SERVICE_LIST)

          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' },
          })
        })
        .catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on update', alertSeverity: 'error' },
          })
        })
        .finally(() => {
          setLoadingSave(false)
        })
    },
  })

  const handleDeleteService = async () => {
    if (!serviceSelected) return
    setLoadingDelete(true)
    await ApiService.Service.remove({
      companyId,
      serviceId: serviceSelected.id,
    })
      .then(async () => {
        if (FileFromStorageList && FileFromStorageList?.length > 0) {
          const promisses: any[] = []
          FileFromStorageList.forEach(file => {
            promisses.push(ApiService.File.deleteFile({ pathFile: file.fullPath }))
          })

          await Promise.all(promisses).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Error deleting images',
                alertSeverity: 'error',
              },
            })
          })
        }

        resetForm()
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Service deleted' },
        })
        navigate(RoutesNames.SERVICE_LIST)
      })
      .catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Service was not deleted' },
        })
      })
      .finally(() => {
        setIsOpenConfirmDelete(false)
        setLoadingDelete(false)
      })
  }

  const RenderFileList = () => {
    const handleRemoveFile = (index: number) => {
      const arrayFiltered = [] as File[]
      fileListToUpload.forEach((item, idx) => {
        if (idx !== index) arrayFiltered.push(item)
      })
      setFileListToUpload(arrayFiltered)
      if (arrayFiltered.length === 0) {
        formik.setFieldValue('hasImage', false)
        formik.setFieldTouched('hasImage', true)
      }
    }

    return (
      <Stack
        display='flex'
        flexDirection='row'
        sx={{
          overflowX: 'auto',
          width: '100%',
          paddingY: 3,
        }}
      >
        {loadingFiles && (
          <Stack width='100%' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Stack>
        )}

        {!loadingFiles &&
          FileFromStorageList?.map((file, index) => {
            const isTheLast = FileFromStorageList.length === index + 1

            return (
              <Stack
                key={file.name}
                sx={{ cursor: 'pointer' }}
                position='relative'
              >
                <Avatar
                  sx={{
                    height: 60,
                    width: 60,
                    marginRight: isTheLast ? 0 : 2,
                    backgroundColor: grey[500],
                    ...(fileFromStorageSelecet?.name === file.name
                      ? { border: `1px solid ${theme.palette.primary.main}` }
                      : {}),
                  }}
                  onClick={() => {
                    window.open(file.url, '_newtab')
                    setFileFromStorageSelecet(file)
                  }}
                  src={file.url}
                />
              </Stack>
            )
          })}

        {!loadingFiles &&
          fileListToUpload.map((file, index) => (
            <Stack
              key={file.name}
              position='relative'
            >
              <Avatar
                sx={{
                  height: 60,
                  width: 60,
                  marginRight: 2,
                }}
                src={URL.createObjectURL(file)}
              />

              <Stack position='absolute' right={-5} top={-10}>
                <IconButton
                  onClick={() => {
                    handleRemoveFile(index)
                  }}
                >
                  <TbTrash color={red[500]} />
                </IconButton>
              </Stack>
            </Stack>
          ))}
      </Stack>
    )
  }

  useEffect(() => {
    if (!serviceSelected) return

    setInitialValues({
      title: serviceSelected.title,
      description: serviceSelected.description,
      isActive: serviceSelected.isActive,
      keyUnique: serviceSelected.id.substring(0, 6).toUpperCase(),
      status: serviceSelected.status,
      clientId: serviceSelected.title,
      typeService: serviceSelected.typeService,
      po: serviceSelected.po,
      hasImage: false,
    })
  }, [serviceSelected])

  useEffect(() => {
    if (!serviceSelected || FileFromStorageList) return

    const getAllFiles = async () => {
      setLoadingFiles(true)
      await ApiService.File.getFiles({
        pathFile: getPathFileCompanyService(companyId, serviceSelected.id),
      })
        .then(resp => {
          setFileFromStorageList(resp)
          if (resp.length > 0) {
            formik.setFieldValue('hasImage', true)
          }
        })
        .finally(() => {
          setLoadingFiles(false)
        })
    }
    getAllFiles()
  }, [serviceSelected, FileFromStorageList])

  useEffect(() => {
    const listType: FormikSelectFieldMenu[] = []
    Object.entries(EnumServiceType).forEach(([key, value]) => {
      listType.push({
        value: key,
        title: value,
      })
    })
    setTypeServiceList(listType)
  }, [])

  useEffect(() => {
    const list: FormikSelectFieldMenu[] = []

    Object.entries(EnumServiceStatus).forEach(([key, value]) => {
      if (
        formik.values.typeService === 'BUCKET' &&
        (EnumServiceStatus.TO_DO === value ||
          EnumServiceStatus.IN_PROGRESS === value ||
          EnumServiceStatus.AWAITING_APPROVAL === value)
      ) {
        return
      }
      list.push({ value: key, title: value })
    })

    if (!serviceSelected && formik.values.typeService === 'BUCKET') {
      formik.setFieldValue('status', list[0].value)
    }

    setStatusList(list.sort((a, b) => (a.title > b.title ? 1 : -1)))
  }, [formik.values.typeService])

  return (
    <Box>
      <Breadcrumb
        pageTitle={serviceSelected ? 'Update of data' : 'Registration'}
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      isMultiple
                      hideMenuItemDelete
                      hideMenu={!!fileFromStorageSelecet}
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      fileUrl={fileFromStorageSelecet?.url}
                      isDisableUpload={!!serviceSelected}
                      setFileToUpload={files => {
                        if (!files) return
                        const temp = [] as File[]
                        Array.from(files).forEach(file => temp.push(file))

                        setFileListToUpload(prev => [...prev, ...temp])
                        formik.setFieldValue('hasImage', true)
                        formik.setFieldTouched('hasImage', false)
                      }}
                      showTextAllowFile
                    />
                    {formik.errors.hasImage && formik.touched.hasImage && (
                      <FormHelperText sx={{ color: red[700] }}>
                        {formik.errors.hasImage}
                      </FormHelperText>
                    )}
                  </Stack>

                  {RenderFileList()}

                  <FormikSwitch
                    size='small'
                    name='isActive'
                    label='Service approved'
                  />

                  {serviceSelected && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        disabled={serviceSelected.status !== 'TO_DO'}
                        onClick={() => {
                          setIsOpenConfirmDelete(true)
                        }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>
                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={8} sm={9}>
                          <FormikTextField
                            name='title'
                            fullWidth
                            label='Name'
                          />
                        </Grid>

                        <Grid item xs={4} sm={3}>
                          <FormikTextField
                            name='keyUnique'
                            label={serviceSelected ? 'Key unique' : 'Key unique soon...'}
                            placeholder=''
                            disabled
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField
                            name='description'
                            fullWidth
                            label='Description'
                            rows={3}
                            multiline
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikSelectField
                            name='typeService'
                            label='Type of service'
                            menuList={typeServiceList}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikSelectField
                            name='status'
                            label='Status'
                            menuList={statusList}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <DrawerChooseClient
                            clientChoosed={clientChoosed}
                            clientIdToSelect={serviceSelected?.clientId}
                            setClientChoosed={client => {
                              setClientChoosed(client)
                              if (client) {
                                formik.setFieldValue('clientId', client.id)
                                return
                              }
                              formik.setFieldValue('clientId', '')
                            }}
                            errorMessage={
                              formik.touched.clientId && formik.errors.clientId
                                ? formik.errors.clientId
                                : undefined
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField name='po' fullWidth label='PO' />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end'>
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        navigate(RoutesNames.SERVICE_LIST)
                      }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      ref={btnSaveServiceRef}
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      disabled={loadingFiles || serviceSelected?.status === 'AWAITING_APPROVAL'}
                      type='submit'
                      loading={loadingSave}
                    >
                      {serviceSelected ? 'Save update' : 'Save service'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${serviceSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteService()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box>
  )
}
