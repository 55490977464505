
import React from 'react'
import {

  TbSearch,
} from 'react-icons/tb'

import moment from 'moment'

import {
  FormControl,
  Stack,
} from '@mui/material'
import { grey, } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface DateRangeProps {
  startDate: number | null,
  endDate: number | null,
  showEndDate: boolean,
  setStartDate: React.Dispatch<React.SetStateAction<number | null>>
  setEndDate: React.Dispatch<React.SetStateAction<number | null>>
  setShowEndDate: React.Dispatch<React.SetStateAction<boolean>>
  showBtnSearch?: boolean
  disabledBtnSearch: boolean
  onSearch?: () => void
}
export const DateRange = ({ startDate, endDate, showEndDate, setStartDate, setEndDate, setShowEndDate, showBtnSearch = true, onSearch, disabledBtnSearch }: DateRangeProps) => (
  <Stack display='flex' flexDirection='row'>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormControl>
        <DatePicker
          slotProps={{
            textField: {
              size: 'small',
            },
            field: {
              clearable: true,
              onClear: () => {
                setStartDate(null)
                setEndDate(null)
              },
            },
          }}
          format='DD/MM/YYYY'
          label="Start date"
          value={startDate ? moment(startDate) : null}
          onChange={(date) => {
            setEndDate(null)

            if (!date) {
              setStartDate(null)
              return
            }
            setStartDate(date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf())
            setShowEndDate(true)
          }}
          sx={{ width: 184 }}
        />
      </FormControl>
    </LocalizationProvider>
    <Stack marginTop={1} mx={0.2}>-</Stack>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormControl>
        <DatePicker
          open={showEndDate}
          slotProps={{
            textField: {
              size: 'small',
            },
            field: {
              clearable: true,
              onClear: () => {
                setEndDate(null)
              },
            },
          }}
          format='DD/MM/YYYY'
          label="End date"
          value={endDate ? moment(endDate) : null}
          onChange={(date) => {
            setShowEndDate(false)
            if (!date) {
              setEndDate(null)
              return
            }
            setEndDate(date.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).valueOf())
          }}
          onOpen={() => { setShowEndDate(true) }}
          onClose={() => { setShowEndDate(false) }}
          sx={{ width: 184 }}
        />
      </FormControl>
    </LocalizationProvider>
    {showBtnSearch && (
      <IconButton
        onClick={() => {
          if (!startDate || !endDate || !onSearch) return
          onSearch()
        }}
        disabled={disabledBtnSearch}
      >
        <TbSearch color={grey[disabledBtnSearch ? 400 : 900]} />
      </IconButton>
    )}
  </Stack>
)
