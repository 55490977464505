/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbDotsVertical,
  TbPencil,
  TbPlus,
  TbSearch,
  TbTrash,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { limit, orderBy, OrderByDirection, QueryFieldFilterConstraint, QueryOrderByConstraint, startAfter, where } from 'firebase/firestore'

import { BoxShadow, Breadcrumb, DialogConfirm, LoadingTable, Status, TableRowsCount } from '@/components'
import { NoContent } from '@/components/no-content'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { formatNumberToLibra } from '@/utils/currency'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

const LIMIT_ROWS = 150
const BOX_HEIGHT = 500
const END_REACHED_THRESHOLD_PERCENTAGE = 95

enum ENUM_ORDER_BY {
  NAME = 'Name',
  DESCRIPTION = 'Description',
  // STOCK_QUANTITY = 'Stock quantity',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

export const Stock = () => {
  const refTable = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [{ company }, dispatch] = useGlobalState()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [stockSelected, setStockSelected] = useState<Stock | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [stockList, setStockList] = useState<Stock[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [stopInfinitScroll, setStopInfinitScroll] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE | ''>('')
  const breadcrumbList: Breadcrumb[] = [
    {
      id: RoutesNames.HOME,
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Stock',
      title: 'Stock of parts',
      isCurrentPage: true,
    },
  ]
  const [searchStock, setSearchStock] = useState<string>('')

  const hasFilterBy = useMemo(() => {
    if (filterBy) return true
    return false
  }, [filterBy])
  const hasSomeFilter = useMemo(() => {
    if (filterBy && searchStock.trim().length > 0) return true
    return false
  }, [filterBy, searchStock])

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    serice: Stock,
  ) => {
    setAnchorEl(event.currentTarget)
    setStockSelected(serice)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteStock = async () => {
    if (!stockSelected) return
    setLoadingDelete(true)
    await ApiService.Stock.remove({ companyId, stockId: stockSelected.id })
      .then(async () => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Part deleted' },
        })
      })
      .catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Part was not deleted', alertSeverity: 'error' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const renderTableBody = () => {
    if (loadingList) {
      return <LoadingTable />
    }

    if (stockList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          mt={10}
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {stockList?.map(stock => (
          <TableRow hover key={stock.id}>
            <TableCell>
              <Typography variant='body2'>{stock.title}</Typography>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>{stock.description}</Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>{stock.quantity}</Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>
                  {formatNumberToLibra({
                    value: stock.price,
                    showCurrency: true,
                  })}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Status
                  title={stock.isActive ? 'Active' : 'Inactive'}
                  type={stock.isActive ? 'SUCCESS' : 'ERROR'}
                />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.STOCK_CRUD, {
                        state: { stockSelected: JSON.stringify(stock) },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, stock)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchStock('')
  }

  const getStock = (
    dataFilterBy: ORDER_BY_TYPE | '',
    dataSearchStock: string,
    isMore?: boolean
  ) => {
    let whereList: QueryFieldFilterConstraint[] = [where('titleSearch', '>=', dataSearchStock.toUpperCase()), where('titleSearch', '<=', dataSearchStock.toUpperCase() + `\uf8ff`)]
    let orderByList: QueryOrderByConstraint[] = [orderBy('titleSearch', 'asc')]
    const limitRows = [limit(LIMIT_ROWS)]

    if (dataFilterBy === 'DESCRIPTION' && dataSearchStock) {
      whereList = [where('descriptionSearch', '>=', dataSearchStock.toUpperCase()), where('descriptionSearch', '<=', dataSearchStock.toUpperCase() + `\uf8ff`)]
      orderByList = [orderBy('descriptionSearch', 'asc')]
    }
    // if (dataFilterBy === 'STOCK_QUANTITY' && dataSearchStock) {
    //   whereList = [where('quantity', '==', Number(dataSearchStock))]
    //   orderByList = [orderBy('createdAt', 'asc')]
    // }

    if (isMore && stockList && stockList.length > 0) {
      let lastData: string | number = stockList[stockList.length - 1].titleSearch
      if (dataFilterBy === 'DESCRIPTION' && dataSearchStock) {
        lastData = stockList[stockList.length - 1].descriptionSearch
      }
      // if (dataFilterBy === 'STOCK_QUANTITY' && dataSearchStock) {
      //   lastData = stockList[stockList.length - 1].titleSearch
      //   // orderByList = []
      // }

      ApiService.Stock.getList({
        companyId,
        setStockList: (moreList) => {
          if (!moreList || moreList.length === 0) {
            setStopInfinitScroll(true)
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Last records loaded!',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }
            })
            return
          }
          setStockList([...stockList, ...moreList])
        },
        setLoadingList: setLoadingMore,
        whereList,
        orderByList,
        limit: limitRows,
        startAfter: [startAfter(lastData)],
        timeDelay: 1000,
      })
      return
    }

    if (refTable.current) refTable.current.scrollTo(0, 0)

    ApiService.Stock.getList({
      companyId,
      setStockList,
      setLoadingList,
      whereList,
      orderByList,
      limit: limitRows,
    })
    setStopInfinitScroll(false)
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event
    const scrollArrivingToEnd = ((scrollTop + clientHeight) / scrollHeight) * 100

    if (stockList && stockList?.length > 0 && !loadingList && !loadingMore && !stopInfinitScroll && scrollArrivingToEnd >= END_REACHED_THRESHOLD_PERCENTAGE) {
      getStock(filterBy, searchStock, true)
    }
  }

  useEffect(() => {
    getStock(filterBy, searchStock)
  }, [])

  // const handleNewField = async () => {
  //   if (!stockList || stockList?.length === 0) return
  //   stockList.forEach((stock, idx) => {
  //     ApiService.Stock.update({
  //       companyId,
  //       stockId: stock.id,
  //       values: {
  //         ...stock,
  //         titleSearch: `${stock.title || ''} ${stock.id || ''}`.toUpperCase(),
  //         descriptionSearch: `${stock.description || ''} ${stock.id || ''
  //           }`.toUpperCase(),
  //       }
  //     }).then(() => {
  //       console.log('SUCESSO');
  //     }).catch(() => {
  //       console.log('ERROR');
  //     })
  //   });
  // }

  return (
    <Box>
      <Breadcrumb pageTitle='Stock' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.STOCK_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <Stack
          display='flex'
          flex={1}
          flexDirection='row'
          justifyContent='space-between'
          padding={2}
        >
          <Stack display='flex' flexDirection='row' flex={1} >
            <FormControl size='small'>
              <InputLabel id='filterBy'>Filter by</InputLabel>
              <Select
                labelId='filterBy'
                value={filterBy}
                label='Filter by'
                onChange={handleChangeOrderBy}
                sx={{ width: 180, mr: 1 }}
              >
                <MenuItem value=''>
                  <em>-- --</em>
                </MenuItem>
                {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              size='small'
              label='Search'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <TbSearch color={grey[900]} />
                  </InputAdornment>
                ),
              }}
              value={searchStock}
              onChange={event => {
                setSearchStock(event.target.value)
              }}
              sx={{ width: 180 }}
              disabled={!hasFilterBy}
            />
            <IconButton
              onClick={() => {
                getStock(filterBy, searchStock)
              }}
              disabled={!hasSomeFilter}
            >
              <TbSearch color={grey[!hasSomeFilter ? 400 : 900]} />
            </IconButton>
          </Stack>

          <Button
            size='small'
            variant='outlined'
            disableElevation
            onClick={() => {
              setFilterBy('')
              setSearchStock('')
              getStock('', '')
            }}
            sx={{ height: 37, ml: 1 }}
            disabled={!hasSomeFilter}
          >
            Clean
          </Button>
        </Stack>

        <TableContainer
          ref={refTable}
          sx={{ height: BOX_HEIGHT }}
          onScroll={(event) => {
            if (stopInfinitScroll) return
            onScroll(event)
          }}
        >
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Description</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Quantity</Typography>
                </TableCell>
                <TableCell width={150}>
                  <Typography variant='subtitle2'>Price</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Status</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
          {loadingMore && <LoadingTable height={100} position='relative' />}
        </TableContainer>
        <TableRowsCount count={(stockList || []).length} />
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.STOCK_CRUD, {
              state: { stockSelected: JSON.stringify(stockSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${stockSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteStock()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box>
  )
}
