import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbDotsVertical,
  TbFileTypeXls,
  TbPencil,
  TbPlus,
  TbSearch,
  TbTrash,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { saveAs } from 'file-saver';
import { limit, orderBy, QueryFieldFilterConstraint, QueryOrderByConstraint, startAfter, where } from 'firebase/firestore'
import { Form, FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import * as XLSX from 'sheetjs-style';

import { BoxShadow, Breadcrumb, DateRange, DialogConfirm, LoadingTable, TableRowsCount } from '@/components'
import { NoContent } from '@/components/no-content'
import { EnumStockRequestStatus } from '@/constants/stock-request'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { discontPercent, formatNumberToLibra, truncateByDecimalPlace } from '@/utils/currency'
import { searchFiledByPartOfText } from '@/utils/firebase';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { green, grey, red, yellow } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { validationSchemaSummary } from './validationSchema'

const LIMIT_ROWS = 150
const LIMIT_ROWS_NO_MORE_SCROLL = 500
const BOX_HEIGHT = 500
const END_REACHED_THRESHOLD_PERCENTAGE = 95

enum ENUM_ORDER_BY {
  // KEY = 'Key',
  TITLE = 'Title',
  // PART_NAME = 'Part name',
  SUPPLIER = 'Supplier name',
  STATUS = 'Status',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

interface InitialValueSummary {
  startDate: null | number
  endDate: null | number
}
const INITIAL_VALUES_SUMARY: InitialValueSummary = {
  startDate: null,
  endDate: null,
}

interface GetStockRequestProps {
  dates?: {
    startDate: number | null,
    endDate: number | null
  },
  subFiltro?: {
    filterBy: ORDER_BY_TYPE | '',
    searchStockRequest?: string,
    searchStatusStockRequest?: StockRequestStatus | ''
  }
}

interface FilterTableProps {
  startDate: null | number
  setStartDate: React.Dispatch<React.SetStateAction<null | number>>
  endDate: null | number
  setEndDate: React.Dispatch<React.SetStateAction<null | number>>
  filterBy: ORDER_BY_TYPE | ''
  setFilterBy: React.Dispatch<React.SetStateAction<ORDER_BY_TYPE | ''>>
  searchStockRequest: string
  setSearchStockRequest: React.Dispatch<React.SetStateAction<string>>
  searchStatusStockRequest: StockRequestStatus | ''
  setSearchStatusStockRequest: React.Dispatch<React.SetStateAction<StockRequestStatus | ''>>
  setIsOpenPrintSummary: React.Dispatch<React.SetStateAction<boolean>>
  hasSubFilter: boolean,
  hasFilterDates: boolean,
  hasSomeFilter: boolean,
  hasFilterBy: boolean,
  getStockRequest: (filter?: GetStockRequestProps) => void
}
const FilterTable = (
  { startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterBy,
    setFilterBy,
    searchStockRequest,
    setSearchStockRequest,
    searchStatusStockRequest,
    setSearchStatusStockRequest,
    setIsOpenPrintSummary,
    hasSubFilter, hasFilterDates, hasSomeFilter, hasFilterBy,
    getStockRequest,
  }:
    FilterTableProps
) => {
  const [showEndDate, setShowEndDate] = useState<boolean>(false)

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchStockRequest('')
    setSearchStatusStockRequest('')
  }

  const handleChangeSearchStatusStockRequest = (event: SelectChangeEvent) => {
    setSearchStatusStockRequest(event.target.value as StockRequestStatus)
    setSearchStockRequest('')
  }

  const onSearch = () => {
    if (hasFilterDates) {
      getStockRequest({
        dates: { startDate, endDate }
      })
    } else {
      getStockRequest({
        subFiltro: {
          filterBy,
          searchStockRequest,
          searchStatusStockRequest
        }
      })
    }
  }

  useEffect(() => {
    if (!hasFilterDates) return

    setFilterBy('')
    setSearchStockRequest('')
    setSearchStatusStockRequest('')

  }, [hasFilterDates])

  return (
    <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between' padding={2}>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        showEndDate={showEndDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setShowEndDate={setShowEndDate}
        showBtnSearch={false}
        disabledBtnSearch={true}
      />

      <Stack display='flex' flexDirection='row'>
        <Stack display='flex' flex={1} flexDirection='row'>
          <FormControl size='small'>
            <InputLabel id='filterBy'>Filter by</InputLabel>
            <Select
              labelId='filterBy'
              value={filterBy}
              label='Filter by'
              onChange={handleChangeOrderBy}
              sx={{ width: 180 }}
            >
              {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack marginTop={1} mx={0.2}>-</Stack>

          {["TITLE", "SUPPLIER"].includes(filterBy) && (
            <TextField
              size='small'
              label='Search'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <TbSearch color={grey[900]} />
                  </InputAdornment>
                ),
              }}
              value={searchStockRequest}
              onChange={event => {
                setSearchStockRequest(event.target.value)
              }}
              sx={{ width: 180 }}
              disabled={!hasFilterBy}
            />
          )}
          {filterBy === 'STATUS' && (
            <FormControl size='small'>
              <InputLabel id='filterBy'>Status</InputLabel>
              <Select
                labelId='filterBy'
                value={searchStatusStockRequest}
                label='Filter by'
                onChange={handleChangeSearchStatusStockRequest}
                sx={{ width: 180, mr: 1 }}
              >
                <MenuItem value=''>
                  <em>-- All --</em>
                </MenuItem>
                {Object.entries(EnumStockRequestStatus).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <IconButton disabled={!hasSomeFilter} onClick={() => { onSearch() }}>
            <TbSearch color={grey[!hasSomeFilter ? 400 : 900]} />
          </IconButton>

          <Button
            size='small'
            variant='outlined'
            disableElevation
            onClick={() => {
              setFilterBy('')
              setSearchStockRequest('')
              setStartDate(null)
              setEndDate(null)
              getStockRequest()
            }}
            sx={{ height: 37, ml: 1, mr: 2 }}
            disabled={!hasSomeFilter}
          >
            Clean
          </Button>

          <Tooltip title='Print invoice summary'>
            <IconButton onClick={() => { setIsOpenPrintSummary(true) }}>
              <TbFileTypeXls color={grey[900]} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

    </Stack>
  )
}

export const StockRequest = () => {
  const refTable = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [{ company }, dispatch] = useGlobalState()
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [stockRequestSelected, setStockRequestSelected] = useState<StockRequest | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [stockRequestList, setStockRequestList] = useState<StockRequest[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [stopInfinitScroll, setStopInfinitScroll] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE | ''>('')
  const breadcrumbList: Breadcrumb[] = [
    {
      id: RoutesNames.HOME,
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'StockRequest',
      title: 'Purchase order',
      isCurrentPage: true,
    },
  ]
  const [searchStockRequest, setSearchStockRequest] = useState<string>('')
  const [searchStatusStockRequest, setSearchStatusStockRequest] = useState<StockRequestStatus | ''>('')
  const [isOpenPrintSummary, setIsOpenPrintSummary] = useState<boolean>(false)
  const [loadingPrintSummary, setLoadingPrintSummary] = useState<boolean>(false)
  const [stockRequestListPrintSummary, setStockRequestListPrintSummary] = useState<StockRequest[] | undefined>(undefined)
  const [startDate, setStartDate] = useState<null | number>(null)
  const [endDate, setEndDate] = useState<null | number>(null)

  const formikFilterSummary = useFormik({
    initialValues: INITIAL_VALUES_SUMARY,
    enableReinitialize: true,
    validationSchema: validationSchemaSummary,
    onSubmit: async (values) => {
      setLoadingPrintSummary(true)

      ApiService.StockRequest.getList({
        companyId,
        setStockRequestList: (list) => {
          if ((list || []).length === 0) {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'There is no data for the selected filter', alertSeverity: 'warning' },
            })
          }
          setStockRequestListPrintSummary(list)
        },
        setLoadingList: setLoadingPrintSummary,
        whereList: [where('dateCreatedAt', '>=', values.startDate), where('dateCreatedAt', '<=', values.endDate)],
        orderByList: [orderBy('dateCreatedAt', 'asc')],
      })
    },
  })

  const hasFilterDates = useMemo(() => {
    if (startDate && endDate) return true
    return false
  }, [startDate, endDate])

  const hasFilterBy = useMemo(() => {
    if (filterBy) return true
    return false
  }, [filterBy])

  const hasSomeFilter = useMemo(() => {
    if ((filterBy && (searchStockRequest.trim().length > 0 || searchStatusStockRequest !== '')) || (startDate && endDate)) return true
    return false
  }, [filterBy, searchStockRequest, searchStatusStockRequest, startDate, endDate])

  const hasSubFilter = useMemo(() => {
    if (filterBy && (searchStockRequest.trim().length > 0 || searchStatusStockRequest)) return true
    return false
  }, [filterBy, searchStockRequest, searchStatusStockRequest])

  const filterStockList = useMemo(() => {
    if (!hasFilterDates) return stockRequestList

    return stockRequestList?.filter(stockRequest => {
      if (filterBy === 'TITLE') {
        return stockRequest.title.toLowerCase().includes(searchStockRequest.toLowerCase())
      }
      if (filterBy === 'SUPPLIER') {
        return stockRequest.supplierName.toLowerCase().includes(searchStockRequest.toLowerCase())
      }
      if (filterBy === 'STATUS') {
        if (!searchStatusStockRequest) return stockRequest
        const arrayStatus = stockRequest.stockRequestList.map((item) => item.status)
        return arrayStatus.includes(searchStatusStockRequest)
      }
      return stockRequest
    })
  },
    [stockRequestList, searchStockRequest, searchStatusStockRequest],
  )

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    serice: StockRequest,
  ) => {
    setAnchorEl(event.currentTarget)
    setStockRequestSelected(serice)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteStock = async () => {
    if (!stockRequestSelected) return
    setLoadingDelete(true)
    await ApiService.StockRequest.remove({ companyId, stockRequestId: stockRequestSelected.id })
      .then(async () => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request deleted' },
        })
      })
      .catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request was not deleted', alertSeverity: 'error' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const renderTableBody = () => {
    if (loadingList) {
      return <LoadingTable />
    }

    if (filterStockList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          mt={10}
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {filterStockList?.map((stock) => {
          let bgRowFullRow = ''
          // const stockRequestListJson = JSON.parse((stock.stockRequestList || '[]') as unknown as string) as StockRequestListItem[]

          if (stock.isPaidOut) {
            bgRowFullRow = green[50]
          } else if (moment(moment().format('YYYY/MM/DD')).isAfter(moment(stock.paymentDue).format('YYYY/MM/DD'))) {
            bgRowFullRow = red[50]
          } else {
            bgRowFullRow = 'transparent'
          }

          return (
            <TableRow hover key={stock.id} sx={{ backgroundColor: bgRowFullRow }}>
              <TableCell>
                <Typography variant='body2'>{stock.id.substring(0, 6).toUpperCase()}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>{stock.title}</Typography>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>{stock.supplierName}</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  {stock.stockRequestList.map((item, idx) => {
                    let bgColorCell = 'transparent'
                    if (!item.quantityRecived) bgColorCell = yellow[100]

                    return (
                      <Stack
                        key={item.part.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          borderBottom: idx < (stock.stockRequestList.length - 1) ? `1px dashed ${grey[900]}` : '',
                          backgroundColor: bgColorCell,
                        }}
                      >
                        <>
                          <Stack sx={{ minWidth: 90 }}>
                            <Typography variant='caption' textOverflow='ellipsis' noWrap width={100}>{item.part.title}</Typography>
                          </Stack>
                          <Stack sx={{ minWidth: 100, borderLeft: `1px dashed ${grey[900]}`, pl: 0.5 }}>
                            <Typography variant='caption'>
                              Qtd Req.: {item.quantityRequest || '-- --'}
                            </Typography>
                          </Stack>
                          <Stack sx={{ minWidth: 100, borderLeft: `1px dashed ${grey[900]}`, pl: 0.5 }}>
                            <Typography variant='caption' >
                              Qtd Rec. {item.quantityRecived || '-- --'}
                            </Typography>
                          </Stack>
                        </>
                      </Stack>
                    )
                  })}
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  {stock?.paymentCreatedAt ? moment(stock.paymentCreatedAt).format('DD/MM/YYYY') : '-- --'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  {stock.createdAt ? moment(stock.dateCreatedAt).format('DD/MM/YYYY') : '-- --'}
                </Typography>
              </TableCell>
              <TableCell>
                <Stack flexDirection='row'>
                  <Tooltip title='Edit'>
                    <IconButton
                      onClick={() => {
                        navigate(RoutesNames.STOCK_REQUEST_CRUD, {
                          state: { stockRequestSelected: JSON.stringify(stock) },
                        })
                      }}
                    >
                      <TbPencil color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Options'>
                    <IconButton
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={event => {
                        handleMenuAction(event, stock)
                      }}
                    >
                      <TbDotsVertical color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  const getStockRequest = (
    filter?: GetStockRequestProps,
    isMore?: boolean
  ) => {

    let whereList: QueryFieldFilterConstraint[] = []
    let orderByList: QueryOrderByConstraint[] = [orderBy('createdAt', 'desc')]
    let limitRows = [limit(LIMIT_ROWS)]
    let hasSubfiltro = false

    if (filter) {
      const { dates, subFiltro } = filter

      if (dates) {
        whereList = [where('createdAt', '>=', dates.startDate), where('createdAt', '<=', dates.endDate)]
        orderByList = [orderBy('createdAt', 'desc')]
      } else if (subFiltro) {
        hasSubfiltro = true
        const { filterBy, searchStatusStockRequest, searchStockRequest } = subFiltro
        if (filterBy === 'TITLE' && searchStockRequest) {
          whereList = searchFiledByPartOfText('titleSearch', searchStockRequest.toUpperCase())
          orderByList = [orderBy('titleSearch', 'asc')]
        }
        if (filterBy === 'SUPPLIER' && searchStockRequest) {
          whereList = searchFiledByPartOfText('supplierNameSearch', searchStockRequest.toUpperCase())
          orderByList = [orderBy('supplierNameSearch', 'asc')]
        }
        if (filterBy === 'STATUS' && searchStatusStockRequest) {
          whereList = [where('partsStatusSearch', 'array-contains-any', [searchStatusStockRequest])]
          orderByList = []
          limitRows = [limit(LIMIT_ROWS_NO_MORE_SCROLL)]
        }
      }
    }

    if (isMore && stockRequestList && stockRequestList.length > 0) {
      const lastData = stockRequestList[stockRequestList.length - 1].createdAt
      ApiService.StockRequest.getList({
        companyId,
        setStockRequestList: (moreList) => {
          if (!moreList || moreList.length === 0) {
            setStopInfinitScroll(true)
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Last records loaded!',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }
            })
            return
          }

          const joinedList = [...stockRequestList, ...moreList]
          if (hasSubfiltro) {
            const sortArray = joinedList?.sort((a, b) => b.createdAt - a.createdAt)
            setStockRequestList(sortArray)
            return
          }

          setStockRequestList(joinedList)
        },
        setLoadingList: setLoadingMore,
        whereList,
        orderByList,
        limit: limitRows,
        startAfter: [startAfter(lastData)],
        timeDelay: 1000,
      })
      return
    }

    if (refTable.current) refTable.current.scrollTo(0, 0)

    ApiService.StockRequest.getList({
      companyId,
      setStockRequestList: (list) => {
        if (hasSubfiltro) {
          const sortArray = list?.sort((a, b) => b.createdAt - a.createdAt)
          setStockRequestList(sortArray)
          return
        }
        setStockRequestList(list)
      },
      setLoadingList,
      whereList,
      orderByList,
      limit: limitRows,
    })

    if (filter?.subFiltro?.filterBy === 'STATUS') {
      setStopInfinitScroll(true)
    } else {
      setStopInfinitScroll(false)
    }
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event
    const scrollArrivingToEnd = ((scrollTop + clientHeight) / scrollHeight) * 100

    if (stockRequestList && stockRequestList?.length > 0 && !loadingList && !stopInfinitScroll && scrollArrivingToEnd >= END_REACHED_THRESHOLD_PERCENTAGE) {

      if (hasFilterDates) {
        getStockRequest({
          dates: { startDate, endDate }
        }, true)
      } else {
        getStockRequest({
          subFiltro: {
            filterBy,
            searchStockRequest,
            searchStatusStockRequest
          }
        }, true)
      }
    }
  }

  useEffect(() => {
    getStockRequest()
  }, [])

  useEffect(() => {
    if (!stockRequestListPrintSummary || (stockRequestListPrintSummary || []).length === 0) return

    const oderToPrint: any[] = []
    const taxPercent = Number(company?.taxPercent || 0)

    let paymentTotalAmt = 0
    let paymentTotalVat = 0
    let paymentTotal = 0

    let noPaymentTotalAmt = 0
    let noPaymentTotalVat = 0
    let noPaymentTotal = 0

    let hasPayment = false

    let indexXls = 1
    stockRequestListPrintSummary.forEach((item, index) => {
      const { stockRequestList, dateCreatedAt, supplierName, isPaidOut, invoiceNumber } = item
      // const stockRequestListJson = JSON.parse(stockRequestList as unknown as string) as StockRequestListItem[]

      stockRequestList.forEach((req) => {
        const { priceBuy, } = req

        const totalPrice = Number(priceBuy)
        const vat = discontPercent({ price: totalPrice, percent: taxPercent })

        const data = {
          Key: (indexXls).toString(),
          Date: moment(dateCreatedAt).format('DD/MM/YYYY'),
          Type: 'Product invoque (From SAP)',
          "Supplier invoice Number": invoiceNumber?.toString() || '',
          "Cliente Name": supplierName,
          Amount: formatNumberToLibra({ value: totalPrice, showCurrency: true }),
          VAT: formatNumberToLibra({ value: vat, showCurrency: true }),
          Total: formatNumberToLibra({ value: totalPrice - vat, showCurrency: true }),
          "Payment Recived": isPaidOut ? 'Yes' : 'No'
        }
        if (isPaidOut) {
          hasPayment = true
          paymentTotalAmt = truncateByDecimalPlace({ value: paymentTotalAmt + totalPrice })
          paymentTotalVat += vat
          paymentTotal = truncateByDecimalPlace({ value: paymentTotal + (totalPrice - vat) })
        } else {
          noPaymentTotalAmt = truncateByDecimalPlace({ value: noPaymentTotalAmt + totalPrice })
          noPaymentTotalVat += vat
          noPaymentTotal = truncateByDecimalPlace({ value: noPaymentTotal + (totalPrice - vat) })
        }
        oderToPrint.push(data)
        indexXls += 1
      })
    });

    const ws = XLSX.utils.aoa_to_sheet([
      [`${company?.name || ''} - Purchase Order Summary`, '', '', '', '', '', '', '', '', '', '', 'Total paid'],
      [moment().format('dddd, MMMM Do YYYY, h:mm:ss a')],
      [""],
      [""],
      ["", '', '', '', '', '', '', '', '', '', '', 'Total unpaid'],
    ]);

    const mergeA1 = { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } };
    const mergeA2 = { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } };
    const mergeA3 = { s: { r: 2, c: 0 }, e: { r: 2, c: 8 } };
    const mergeL1 = { s: { r: 0, c: 11 }, e: { r: 0, c: 13 } };
    const mergeL5 = { s: { r: 4, c: 11 }, e: { r: 4, c: 13 } };

    if (!ws['!merges']) ws['!merges'] = [];
    ws['!merges'].push(mergeA1);
    ws['!merges'].push(mergeA2);
    ws['!merges'].push(mergeA3);
    ws['!merges'].push(mergeL1);
    ws['!merges'].push(mergeL5);

    XLSX.utils.sheet_add_json(ws, [{
      "Total Amt": formatNumberToLibra({ value: paymentTotalAmt, showCurrency: true }),
      "Total VAT": formatNumberToLibra({ value: paymentTotalVat, showCurrency: true }),
      "Total": formatNumberToLibra({ value: paymentTotal, showCurrency: true })
    },
    ], { origin: "L2" });

    XLSX.utils.sheet_add_json(ws, [
      {
        "Total Amt": formatNumberToLibra({ value: noPaymentTotalAmt, showCurrency: true }),
        "Total VAT": formatNumberToLibra({ value: noPaymentTotalVat, showCurrency: true }),
        "Total": formatNumberToLibra({ value: noPaymentTotal, showCurrency: true })
      },
    ], { origin: "L6" });

    XLSX.utils.sheet_add_json(ws, oderToPrint, { origin: "A4" });

    const styleHeader = {
      fill: { bgColor: { rgb: '00000000' } },
      font: { sz: 13, color: { rgb: "FFFFFFFF" } },
    };
    const styleHeaderTotal = {
      fill: { bgColor: { rgb: '00000000' } },
      font: { sz: 13, color: { rgb: "FFFFFFFF" } },
    };
    const styleHeaderTotalBorder = {
      border: {
        right: {
          style: "thin",
          color: "000000"
        },
        left: {
          style: "thin",
          color: "000000"
        },
        top: {
          style: "thin",
          color: "000000"
        },
        bottom: {
          style: "thin",
          color: "000000"
        }
      }
    };

    ws.A1.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.A2.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 13, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.A3.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 13, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };

    if (hasPayment) {
      ws.A4.s = styleHeader;
      ws.B4.s = styleHeader;
      ws.C4.s = styleHeader;
      ws.D4.s = styleHeader;
      ws.E4.s = styleHeader;
      ws.F4.s = styleHeader;
      ws.G4.s = styleHeader;
      ws.H4.s = styleHeader;
      ws.I4.s = styleHeader;
    }

    ws.L1.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.L2.s = styleHeaderTotal;
    ws.L3.s = styleHeaderTotalBorder;
    ws.M2.s = styleHeaderTotal;
    ws.M3.s = styleHeaderTotalBorder;
    ws.N2.s = styleHeaderTotal;
    ws.N3.s = styleHeaderTotalBorder;

    ws.L5.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.L6.s = styleHeaderTotal;
    ws.L7.s = styleHeaderTotalBorder;
    ws.M6.s = styleHeaderTotal;
    ws.M7.s = styleHeaderTotalBorder;
    ws.N6.s = styleHeaderTotal;
    ws.N7.s = styleHeaderTotalBorder;

    const wscols = [
      { wch: 6 },
      { wch: 14 },
      { wch: 26 },
      { wch: 25 },
      { wch: 30 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 16 },

      { wch: 2 },
      { wch: 2 },

      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    ws['!cols'] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `purchase_order_summary_${moment().valueOf()}.xlsx`);

    setIsOpenPrintSummary(false)
    setLoadingPrintSummary(false)
    formikFilterSummary.resetForm()

  }, [stockRequestListPrintSummary])

  return (
    <Box>
      <Breadcrumb pageTitle='Stock' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.STOCK_REQUEST_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <FilterTable
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          searchStockRequest={searchStockRequest}
          searchStatusStockRequest={searchStatusStockRequest}
          setSearchStatusStockRequest={setSearchStatusStockRequest}
          setSearchStockRequest={setSearchStockRequest}
          setIsOpenPrintSummary={setIsOpenPrintSummary}
          hasSubFilter={hasSubFilter}
          hasFilterBy={hasFilterBy}
          hasSomeFilter={hasSomeFilter}
          hasFilterDates={hasFilterDates}
          getStockRequest={(filter) => { getStockRequest(filter, false) }}
        />
        <TableContainer
          ref={refTable}
          sx={{ height: BOX_HEIGHT }}
          onScroll={(event) => {
            if (stopInfinitScroll) return
            onScroll(event)
          }}
        >
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width={80}>
                  <Typography variant='subtitle2'>Key</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Title</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Supplier</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Parts</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Payment dt</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Created at</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
          {loadingMore && <LoadingTable height={100} position='relative' />}
        </TableContainer>
        <TableRowsCount count={(filterStockList || []).length} />
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.STOCK_REQUEST_CRUD, {
              state: { stockRequestSelected: JSON.stringify(stockRequestSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
          disabled={stockRequestSelected?.isPaidOut}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Filter'
        description={`Choose the data you want to filter`}
        open={isOpenPrintSummary}
        onCloseDialog={() => {
          setIsOpenPrintSummary(false)
          formikFilterSummary.resetForm()
        }}
        btnOkProps={{
          onClick: () => { formikFilterSummary.submitForm() },
          loading: loadingPrintSummary,
        }}
        btnOkTitle='Filter'
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            formikFilterSummary.resetForm()
            setIsOpenPrintSummary(false)
          },
        }}
      >
        <FormikProvider value={formikFilterSummary}>
          <Form>
            <Grid container spacing={2} rowSpacing={3} mt={0.5}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['DatePicker']}>
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{
                          textField: {
                            name: 'startDate',
                            error: formikFilterSummary.touched.startDate && Boolean(formikFilterSummary.errors.startDate),
                            helperText: (formikFilterSummary.touched.startDate && Boolean(formikFilterSummary.errors.startDate)) ? formikFilterSummary.errors.startDate?.toString() || '' : ''
                          },
                          field: {
                            clearable: true,
                            onClear: () => { formikFilterSummary.setFieldValue('startDate', null) },
                          },
                        }}
                        format='DD/MM/YYYY'
                        label="Start date"
                        name='startDate'
                        value={formikFilterSummary.values.startDate ? moment(formikFilterSummary.values.startDate) : null}
                        onChange={(date) => {
                          if (!date) {
                            formikFilterSummary.setFieldValue('startDate', null, false)
                            formikFilterSummary.setFieldTouched('startDate', true)
                          } else {
                            formikFilterSummary.setFieldValue('startDate', date.valueOf())
                            formikFilterSummary.setFieldValue('endDate', null)
                          }
                        }}
                      />
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['DatePicker']}>
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{
                          textField: {
                            name: 'endDate',
                            error: formikFilterSummary.touched.endDate && Boolean(formikFilterSummary.errors.endDate),
                            helperText: (formikFilterSummary.touched.endDate && Boolean(formikFilterSummary.errors.endDate)) ? formikFilterSummary.errors.endDate?.toString() || '' : ''
                          },
                          field: {
                            clearable: true,
                            onClear: () => { formikFilterSummary.setFieldValue('endDate', null) },
                          },
                        }}
                        format='DD/MM/YYYY'
                        label="End date"
                        name='endDate'
                        value={formikFilterSummary.values.endDate ? moment(formikFilterSummary.values.endDate) : null}
                        onChange={(date) => {
                          if (!date) {
                            formikFilterSummary.setFieldValue('endDate', null, false)
                            formikFilterSummary.setFieldTouched('endDate', true)
                          } else {
                            formikFilterSummary.setFieldValue('endDate', date.valueOf())
                          }
                        }}
                        minDate={formikFilterSummary?.values.startDate ? moment(formikFilterSummary?.values.startDate) : undefined}
                      />
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogConfirm>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${stockRequestSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteStock()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box>
  )
}
