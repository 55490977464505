import * as yup from 'yup'

export const validationSchema = yup.object({
  title: yup.string().required('Field is required'),
  supplierId: yup.string().required('Field is required'),
  paymentDue: yup.number().required('Field is required'),

  stockRequestList: yup
    .array()
    .of(
      yup.object().shape({
        priceBuy: yup
          .number()
          .positive('Invalid price')
          .required('Field is required'),
        priceSale: yup
          .number()
          .positive('Invalid price')
          .required('Field is required'),
        quantityRequest: yup
          .number()
          .positive('Invalid quantity')
          .required('Field is required'),
      }),
    )
    .min(1, 'Field must have at least 1 items'),
})

// https://stackoverflow.com/questions/63534689/how-to-validate-individual-element-of-an-array-of-json-objects-using-yup
