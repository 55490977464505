/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbArrowDown,
  TbArrowUp,
  TbDotsVertical,
  TbPencil,
  TbPlus,
  TbPrinter,
  TbSearch,
  TbTrash,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import { limit, orderBy, QueryFieldFilterConstraint, startAfter, where } from 'firebase/firestore'
import moment from 'moment'

import {
  BoxShadow,
  Breadcrumb,
  DateRange,
  DialogConfirm,
  LoadingTable,
  StatusServiceFlow,
  TableRowsCount,
} from '@/components'
import { NoContent } from '@/components/no-content'
import { EnumServiceStatus, EnumServiceType } from '@/constants/service'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { deleteFile, getFiles } from '@/services/api/file'
import { formatNumberToLibra } from '@/utils/currency'
import { searchFiledByPartOfText } from '@/utils/firebase'
import { getPathFileCompanyService } from '@/utils/storage'
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { amber, grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

import { PrintService } from './print-service'
import { PrintServiceLote } from './print-service-lote'

const LIMIT_ROWS = 150
const LIMIT_ROWS_NO_MORE_SCROLL = 500
const BOX_HEIGHT = 500
const END_REACHED_THRESHOLD_PERCENTAGE = 95

enum ENUM_ORDER_BY {
  NAME = 'Name',
  CLIENT = 'Client',
  STATUS = 'Status',
  KEY_UNIQUE = 'Key unique',
  PO = 'PO',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

interface DataPrintService {
  service: Service
  client: Client
}

interface GetStockRequestProps {
  dates?: {
    startDate: number | null,
    endDate: number | null
  },
  subFiltro?: {
    filterStatus?: ServiceStatusType | ''
    filterBy: ORDER_BY_TYPE | '',
    searchService?: string,
    filterEmpyt?: boolean
  }
}
interface FilterTableProps {
  startDate: null | number
  setStartDate: React.Dispatch<React.SetStateAction<null | number>>
  endDate: null | number
  setEndDate: React.Dispatch<React.SetStateAction<null | number>>
  filterStatus: ServiceStatusType | ''
  setFilterStatus: React.Dispatch<React.SetStateAction<ServiceStatusType | ''>>
  filterBy: ORDER_BY_TYPE | ''
  setFilterBy: React.Dispatch<React.SetStateAction<ORDER_BY_TYPE | ''>>
  searchService: string
  setSearchService: React.Dispatch<React.SetStateAction<string>>
  filterEmpyt: boolean
  setFilterEmpyt: React.Dispatch<React.SetStateAction<boolean>>
  setPrintServiceLote: React.Dispatch<React.SetStateAction<boolean>>
  hasFilterServiceList: boolean
  hasSomeFilter: boolean,
  hasFilterDates: boolean
  getService: (filter?: GetStockRequestProps) => void
}
const FilterTable = (
  { startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterStatus,
    setFilterStatus,
    filterBy,
    setFilterBy,
    searchService,
    setSearchService,
    filterEmpyt,
    setFilterEmpyt,
    setPrintServiceLote,
    hasFilterServiceList,
    hasSomeFilter,
    hasFilterDates,
    getService,
  }:
    FilterTableProps
) => {
  const [showEndDate, setShowEndDate] = useState<boolean>(false)

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setFilterStatus(event.target.value as ServiceStatusType)
    // setStopInfinitScroll(false)
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchService('')
  }

  const handleChangeFilterEmpyt = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilterEmpyt(event.target.checked)
  }

  const onSearch = () => {
    if (hasFilterDates) {
      getService({
        dates: { startDate, endDate }
      })
    } else {
      getService({
        subFiltro: {
          filterStatus,
          filterBy,
          searchService,
          filterEmpyt
        }
      })
    }
  }

  return (
    <Stack
      display='flex'
      flex={1}
      flexDirection='row'
      justifyContent='space-between'
      padding={2}
    >
      <DateRange
        startDate={startDate}
        endDate={endDate}
        showEndDate={showEndDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setShowEndDate={setShowEndDate}
        showBtnSearch={false}
        disabledBtnSearch={true}
      />

      <Stack display='flex' flexDirection='row'>
        <FormControl size='small'>
          <InputLabel id='filterBy'>Filter by</InputLabel>
          <Select
            labelId='filterBy'
            value={filterBy}
            label='Filter by'
            onChange={handleChangeOrderBy}
            sx={{ width: 130 }}
          >
            <MenuItem value=''>
              <em>-- --</em>
            </MenuItem>
            {Object.entries(ENUM_ORDER_BY).filter((item) => {
              if (!hasFilterDates && item.includes('KEY_UNIQUE')) return false
              return true
            }).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack marginTop={1} mx={0.2}>-</Stack>
        {['CLIENT', 'KEY_UNIQUE', 'NAME'].includes(filterBy) && (
          <TextField
            size='small'
            label='Search'
            value={searchService}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <TbSearch color={grey[900]} />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              setSearchService(event.target.value)
            }}
            sx={{ width: 138 }}
            disabled={!filterBy}
          />
        )}
        {filterBy === 'STATUS' && (
          <FormControl size='small'>
            <InputLabel id='filterStatusFlow'>Status Flow</InputLabel>
            <Select
              labelId='filterStatusFlow'
              value={filterStatus}
              label='Status Flow'
              onChange={handleChangeStatus}
              sx={{ width: 130, mr: 1 }}
            >
              <MenuItem value=''>
                <em>-- All --</em>
              </MenuItem>
              {Object.entries(EnumServiceStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {filterBy === 'PO' && (
          <FormControlLabel
            control={
              <Switch
                checked={filterEmpyt}
                size='small'
                onChange={handleChangeFilterEmpyt}
              />
            }
            label={<Typography variant='body2'>{filterEmpyt ? 'Is empyt' : 'Not empyt'}</Typography>}
            sx={{ marginLeft: 2 }}
          />
        )}

        <IconButton disabled={!hasSomeFilter} onClick={() => { onSearch() }}>
          <TbSearch color={grey[!hasSomeFilter ? 400 : 900]} />
        </IconButton>

        <Button
          size='small'
          variant='outlined'
          disableElevation
          onClick={() => {
            setFilterStatus('')
            setFilterBy('')
            setSearchService('')
            setFilterEmpyt(false)
            setStartDate(null)
            setEndDate(null)
            getService()
          }}
          sx={{ height: 37, ml: 1 }}
          disabled={!hasSomeFilter}
        >
          Clean
        </Button>

        <IconButton
          onClick={() => { setPrintServiceLote(true) }}
          disabled={!hasFilterServiceList}
          sx={{ marginLeft: 2 }}
        >
          <TbPrinter color={grey[!hasFilterServiceList ? 400 : 900]}
          />
        </IconButton>
      </Stack>
    </Stack>
  )
}

interface RenderTableBodyProps {
  filterServiceList?: Service[]
  loadingList: boolean
  handleMenuAction: (event: React.MouseEvent<HTMLButtonElement>, serice: Service) => void
}
const RenderTableBody = ({ filterServiceList, loadingList, handleMenuAction }: RenderTableBodyProps) => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState<number>(-1);

  if (loadingList) {
    return <LoadingTable />
  }

  if (filterServiceList?.length === 0) {
    return (
      <Stack
        display='flex'
        alignItems='center'
        width='100%'
        position='absolute'
        mt={10}
      >
        <NoContent />
      </Stack>
    )
  }

  return (
    <TableBody>
      {filterServiceList?.map((service, index) => (
        <>
          <TableRow hover={open !== index} key={service.id} sx={{ backgroundColor: open === index ? amber[50] : '' }}>
            <TableCell>
              <Typography variant='subtitle2'>{service.id.substring(0, 6).toUpperCase()}</Typography>
              <Stack ml={1.5}>
                <Tooltip title='More details'>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (open === index) {
                        setOpen(-1)
                      } else {
                        setOpen(index)
                      }
                    }}
                    sx={{ width: 24, height: 24 }}
                  >
                    {open === index ? <TbArrowUp color={grey[900]} /> : <TbArrowDown color={grey[900]} />}
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
            <TableCell>
              <Typography variant='body2'
                textOverflow='ellipsis'
                noWrap
                width={120}>{service.title}</Typography>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography
                  variant='body2'
                  textOverflow='ellipsis'
                  noWrap
                  width={120}
                >
                  {service.clientFullname}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Typography variant='caption' minWidth={80}>Created:</Typography>
                <Typography variant='caption' fontWeight='bold'>
                  {moment(service.createdAt).format('DD/MM/YYYY, hh:mm')}
                </Typography>
              </Stack>
              {service.assignServiceWorkerToDoCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>To Do:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerToDoCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerInProgressCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>In Progress:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerInProgressCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerAwaitingApprovalCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Awaitng:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerAwaitingApprovalCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerApprovedCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Approved:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerApprovedCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerStartWorkCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Start work:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerStartWorkCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerWorkFinishCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Work finish:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerWorkFinishCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>

              )}
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>
                  <Stack flexDirection='row'>
                    <StatusServiceFlow
                      title={EnumServiceStatus[service.status]}
                      serviceStatusType={service.status}
                    />
                  </Stack>
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.SERVICE_CRUD, {
                        state: { serviceSelected: JSON.stringify(service) },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, service)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>

          <TableRow sx={{ 'td, th': { border: 0 }, backgroundColor: open === index ? amber[50] : '' }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open === index} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, mb: 3 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Details of service: {service.title}
                  </Typography>

                  <BoxShadow>
                    <TableContainer>
                      <Table stickyHeader sx={{ position: 'relative' }} size='small'>
                        <TableHead>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell width={120}>
                              <Typography variant='subtitle2'>PO</Typography>
                            </TableCell>
                            <TableCell width={120}>
                              <Typography variant='subtitle2'>Price/Hour</Typography>
                            </TableCell>
                            <TableCell width={80}>
                              <Typography variant='subtitle2'>Type</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='subtitle2'>Observations</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant='body2'>{service.po || '-- --'}</Typography>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection='column'>
                                <Typography variant='body2'>
                                  {service.totalPrice ? formatNumberToLibra({
                                    value: service.totalPrice, showCurrency: true
                                  }) : '-- --'}
                                </Typography>
                                <Typography variant='caption' color={grey[500]} fontWeight={500}>
                                  {service.workHours ? `Work hour: ${service.workHours}` : '-- --'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection='column'>
                                <Typography variant='body2'>
                                  {EnumServiceType[service.typeService]}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection='column'>
                                <Typography variant='caption'>{service.observations || '-- --'}</Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BoxShadow>

                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ),
      )}
    </TableBody>
  )
}

export const Service = () => {
  const refTable = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const printServiceRef = useRef<HTMLDivElement>(null)
  const [{ company }, dispatch] = useGlobalState()
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [serviceSelected, setServiceSelected] = useState<Service | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [serviceList, setServiceList] = useState<Service[] | undefined>(
    undefined,
  )
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [stopInfinitScroll, setStopInfinitScroll] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [printService, setPrintService] = useState<
    DataPrintService | undefined
  >(undefined)
  const [printServiceLote, setPrintServiceLote] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE | ''>('')
  const [filterStatus, setFilterStatus] = useState<ServiceStatusType | ''>('')
  const [filterEmpyt, setFilterEmpyt] = useState<boolean>(false)
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Services',
      title: 'Services',
      isCurrentPage: true,
    },
  ]
  const [searchService, setSearchService] = useState<string>('')
  const [startDate, setStartDate] = useState<null | number>(null)
  const [endDate, setEndDate] = useState<null | number>(null)

  const hasFilterStatus = useMemo(() => {
    if (filterStatus) return true
    return false
  }, [filterStatus, searchService, filterEmpyt])
  const hasFilterService = useMemo(() => {
    if (searchService && searchService.trim().length > 0) return true
    return false
  }, [searchService])
  const hasFilterEmpyt = useMemo(() => {
    if (filterBy !== 'PO') return undefined
    return filterEmpyt
  }, [filterBy])
  const hasFilterDates = useMemo(() => {
    if (startDate && endDate) return true
    return false
  }, [startDate, endDate])
  const hasSomeFilter = useMemo(() => {
    if (hasFilterStatus || hasFilterService || hasFilterEmpyt !== undefined || (startDate && endDate)) return true
    return false
  }, [hasFilterStatus, hasFilterService, hasFilterEmpyt, startDate, endDate])

  const filterServiceList = useMemo(
    () => {
      if (!hasFilterDates) return serviceList || []

      if (!serviceList || serviceList.length === 0) return [] as Service[]
      let listTemp = []

      if (filterStatus) {
        listTemp = serviceList?.filter((service) => service.status === filterStatus)
      } else {
        listTemp = [...serviceList]
      }

      return listTemp?.filter(service => {

        if (filterBy === 'NAME') {
          return service.title
            .toLowerCase()
            .includes(searchService.toLowerCase())
        }

        if (filterBy === 'CLIENT') {
          return service.clientFullname
            .toLowerCase()
            .includes(searchService.toLowerCase())
        }

        if (filterBy === 'PO') {
          if (filterEmpyt) {
            return !service?.po
          }
          return (service?.po || '')
            .toLowerCase()
            .includes(searchService.toLowerCase())
        }

        return service.id.substring(0, 6).toUpperCase()
          .toLowerCase()
          .includes(searchService.toLowerCase())
      })
    },
    [serviceList, filterStatus, searchService, filterEmpyt],
  )

  const hasFilterServiceList = useMemo(() => {
    if (!filterServiceList || filterServiceList?.length === 0) return false
    return true
  }, [filterServiceList])

  const handleShowPrint = useReactToPrint({
    content: () => printServiceRef.current,
    documentTitle: `Print-Service-${new Date().getTime()}`,
    onBeforePrint: () => {
      console.log('before printing...')
    },
    onAfterPrint: () => {
      setPrintService(undefined)
    },
    removeAfterPrint: true,
  })

  const handleShowPrintLote = useReactToPrint({
    content: () => printServiceRef.current,
    documentTitle: `Print-Service-lote-${new Date().getTime()}`,
    onBeforePrint: () => {
      console.log('before printing...')
    },
    onAfterPrint: () => {
      setPrintServiceLote(false)
    },
    removeAfterPrint: true,
  })

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    serice: Service,
  ) => {
    setAnchorEl(event.currentTarget)
    setServiceSelected(serice)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteService = async () => {
    if (!serviceSelected) return
    setLoadingDelete(true)

    await ApiService.Service.remove({
      companyId,
      serviceId: serviceSelected.id,
    })
      .then(async () => {
        await getFiles({
          pathFile: getPathFileCompanyService(companyId, serviceSelected.id),
        }).then(async listFile => {
          if (listFile.length === 0) return

          const promises: any[] = []
          listFile.forEach(file => {
            promises.push(deleteFile({ pathFile: file.fullPath }))
          })
          await Promise.all(promises).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Error deleting images',
                alertSeverity: 'error',
              },
            })
          })
        })

        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Service deleted' },
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Service was not deleted' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
        setIsOpenConfirmDelete(false)
      })
  }

  const handlePrint = async () => {
    const client = await ApiService.Client.getOne({
      companyId,
      clientId: serviceSelected?.clientId || '',
    })

    if (!serviceSelected || !client) return

    setPrintService({
      service: serviceSelected,
      client,
    })
  }

  const getService = (
    filter?: GetStockRequestProps,
    isMore?: boolean
  ) => {
    let whereList: QueryFieldFilterConstraint[] = []
    let orderByList = [orderBy('createdAt', 'desc')]
    let limitRows = [limit(LIMIT_ROWS)]
    let hasSubfiltro = false

    if (filter) {
      const { dates, subFiltro } = filter

      if (dates) {
        whereList = [where('createdAt', '>=', dates.startDate), where('createdAt', '<=', dates.endDate)]
        orderByList = [orderBy('createdAt', 'desc')]
      } else if (subFiltro) {
        hasSubfiltro = true
        const { filterStatus, filterBy, searchService, filterEmpyt } = subFiltro
        if (filterBy === 'CLIENT' && searchService) {
          whereList = searchFiledByPartOfText('clientFullnameSearch', searchService.toUpperCase())
          orderByList = [orderBy('clientFullnameSearch', 'asc')]
        }
        if (filterBy === 'NAME' && searchService) {
          whereList = searchFiledByPartOfText('titleSearch', searchService.toUpperCase())
          orderByList = [orderBy('titleSearch', 'asc')]
        }
        if (filterBy === 'PO' && filterEmpyt !== undefined) {
          whereList = [where('po', filterEmpyt ? '==' : '!=', '')]
          orderByList = []
          limitRows = [limit(LIMIT_ROWS_NO_MORE_SCROLL)]
        }
        if (filterBy === 'STATUS' && filterStatus) {
          whereList = [where('status', '==', filterStatus)]
          orderByList = []
          limitRows = [limit(LIMIT_ROWS_NO_MORE_SCROLL)]
        }
      }
    }

    if (isMore && serviceList && serviceList.length > 0) {
      const lastData = serviceList[serviceList.length - 1].createdAt
      ApiService.Service.getList({
        companyId,
        setServiceList: (moreList) => {
          if (!moreList || moreList.length === 0) {
            setStopInfinitScroll(true)
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Last records loaded!',
                alertSeverity: 'warning',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }
            })
            return
          }
          const joinedList = [...serviceList, ...moreList]
          if (hasSubfiltro) {
            const sortArray = joinedList?.sort((a, b) => b.createdAt - a.createdAt)
            setServiceList(sortArray)
            return
          }
          setServiceList(joinedList)
        },
        setLoadingList: setLoadingMore,
        whereList,
        orderByList,
        limit: limitRows,
        startAfter: [startAfter(lastData)],
        timeDelay: 1000,
      })
      return

    }
    if (refTable.current) refTable.current.scrollTo(0, 0)

    ApiService.Service.getList({
      companyId,
      setServiceList: (list) => {
        if (hasSubfiltro) {
          const sortArray = list?.sort((a, b) => b.createdAt - a.createdAt)
          setServiceList(sortArray)
          return
        }
        setServiceList(list)
      },
      setLoadingList,
      whereList,
      orderByList,
      limit: limitRows,
    })

    if (['STATUS', 'PO'].includes(filter?.subFiltro?.filterBy || '')) {
      setStopInfinitScroll(true)
    } else {
      setStopInfinitScroll(false)
    }
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event
    const scrollArrivingToEnd = ((scrollTop + clientHeight) / scrollHeight) * 100

    if (serviceList && serviceList?.length > 0 && !loadingList && !stopInfinitScroll && scrollArrivingToEnd >= END_REACHED_THRESHOLD_PERCENTAGE) {
      if (hasFilterDates) {
        getService({
          dates: { startDate, endDate }
        }, true)
      } else {
        getService({
          subFiltro: {
            filterStatus,
            filterBy,
            searchService,
            filterEmpyt,
          }
        }, true)
      }
    }
  }

  useEffect(() => {
    getService()
  }, [])

  useEffect(() => {
    if (!printService) return
    handleShowPrint(null, () => printServiceRef.current)
  }, [printService])

  useEffect(() => {
    if (!printServiceLote) return
    handleShowPrintLote(null, () => printServiceRef.current)
  }, [printServiceLote])

  return (
    <Box>
      <Breadcrumb pageTitle='List of services' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={async () => {
              navigate(RoutesNames.SERVICE_CRUD)
              // console.log('FULL TABLE: ', MOCK_SERVICE)
              // console.log('QTD: ', (MOCK_SERVICE || []).length);
              // console.log('----------------------------------------------');
              // MOCK_SERVICE.forEach(async (item) => {
              //   const temp = item as Service
              //   await ApiService.Service.update({
              //     companyId,
              //     serviceId: temp.id,
              //     values: temp
              //   }).then(() => {
              //     console.log('SUCESSO');
              //   }).catch(() => {
              //     console.log('ERROR');
              //   })
              // });
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <FilterTable
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          searchService={searchService}
          setSearchService={setSearchService}
          filterEmpyt={filterEmpyt}
          setFilterEmpyt={setFilterEmpyt}
          setPrintServiceLote={setPrintServiceLote}
          hasFilterServiceList={hasFilterServiceList}
          hasSomeFilter={hasSomeFilter}
          hasFilterDates={hasFilterDates}
          getService={(filter) => { getService(filter, false) }}
        />

        <TableContainer
          ref={refTable}
          sx={{ height: BOX_HEIGHT }}
          onScroll={(event) => {
            if (stopInfinitScroll) return
            onScroll(event)
          }}
        >
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width={30}>
                  <Typography variant='subtitle2'>Key</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Client name</Typography>
                </TableCell>
                <TableCell width={240}>
                  <Typography variant='subtitle2'>Status date</Typography>
                </TableCell>
                <TableCell width={150}>
                  <Typography variant='subtitle2'>Flow</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            <RenderTableBody
              filterServiceList={filterServiceList}
              loadingList={loadingList}
              handleMenuAction={handleMenuAction}
            />
          </Table>
          {loadingMore && <LoadingTable height={100} position='relative' />}
        </TableContainer>
        <TableRowsCount count={filterServiceList.length} />
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.SERVICE_CRUD, {
              state: { serviceSelected: JSON.stringify(serviceSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            handlePrint()
          }}
        >
          <ListItemIcon>
            <TbPrinter size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Print</Typography>
        </MenuItem>
        <MenuItem
          disabled={serviceSelected?.status !== 'TO_DO'}
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${serviceSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteService()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />

      {printService && (
        <div style={{ visibility: 'hidden', height: 0 }}>
          <PrintService
            ref={printServiceRef}
            service={printService.service}
            client={printService.client}
          />
        </div>
      )}

      {printServiceLote && hasFilterServiceList && (
        <div style={{ visibility: 'hidden', height: 0 }}>
          <PrintServiceLote
            ref={printServiceRef}
            serviceList={filterServiceList}
          />
        </div>
      )}

    </Box>
  )
}

