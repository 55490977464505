import * as React from 'react'

import { Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'


interface TableRowsCountProps {
  count?: number
}
export const TableRowsCount = ({ count = 0 }: TableRowsCountProps) => (
  <Stack display='flex' flexDirection='row' justifyContent='flex-end' height={60} px={2} alignItems='center' sx={{ borderTop: `1px solid ${grey[50]}` }}>
    <Typography variant='body2' display='inline-block'>Rows count:</Typography>
    <Typography variant='body2' fontWeight={500} ml={0.5}>{count}</Typography>
  </Stack>
)
