import React, { useState } from 'react'

import { Form, FormikProvider, useFormik } from 'formik'

import LogoMarca from '@/assets/images/logo.jpeg'
import { FormikTextField } from '@/components/formik-text-field'
import { useGlobalState } from '@/context'
import * as ApiService from '@/services/api'
import LoadingButton from '@mui/lab/LoadingButton'
import { Avatar, Box } from '@mui/material'
import Stack from '@mui/material/Stack'

import { validationSchema } from './validationSchema'

const INITIAL_VALUES = {
  email: '',
  password: '',
}

export const Login = () => {
  const [, dispatch] = useGlobalState()
  const [loading, setLoading] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    validateOnBlur: false,
    onSubmit: async values => {
      const { email, password } = values
      setLoading(true)
      await ApiService.Auth.singIn({ email, password })
        .catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: {
              message: 'E-mail or Password incorrect',
              alertSeverity: 'error',
            },
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <Stack flex={1} display='flex' justifyContent='center' alignItems='center'>
      <Stack>
        <Avatar src={LogoMarca} sx={{ width: 130, height: 130 }} />
      </Stack>
      <Box mb={4} />

      <FormikProvider value={formik}>
        <Form>
          <Stack minWidth={340}>
            <FormikTextField
              fullWidth
              label='E-mail'
              name='email'
              type='email'
            />
            <Box mb={2} />

            <FormikTextField
              fullWidth
              label='Password'
              name='password'
              type='password'
            />
            <Box mb={3} />
            <LoadingButton
              variant='contained'
              fullWidth
              size='large'
              type='submit'
              color='btnPrimaryFill'
              loading={loading}
            >
              Login
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  )
}
