import {
  addDoc,
  collection,
  deleteDoc,
  onSnapshot,
  query,
  setDoc,
  doc,
  getDoc,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  QueryLimitConstraint,
} from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'
import { getPathFileCompanyClient } from '@/utils/storage'
import { delay } from '@/utils/tricks'

import { deleteFile } from '../file'

interface GetListProps {
  companyId: string
  setClientList: React.Dispatch<React.SetStateAction<Client[] | undefined>>
  setLoadingList: React.Dispatch<React.SetStateAction<boolean>>
  whereList?: QueryFieldFilterConstraint[]
  orderByList?: QueryOrderByConstraint[]
  startAfter?: QueryStartAtConstraint[]
  limit?: QueryLimitConstraint[]
  timeDelay?: number
}
export const getList = async ({
  companyId,
  setClientList,
  setLoadingList,
  whereList,
  orderByList,
  startAfter,
  limit,
  timeDelay,
}: GetListProps) => {
  setLoadingList(true)

  if (timeDelay) await delay(timeDelay)

  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_CLIENT,
    ),
    ...(whereList || []),
    ...(orderByList || []),
    ...(startAfter || []),
    ...(limit || []),
  )

  onSnapshot(
    q,
    querySnapshot => {
      const clientList: Client[] = []
      querySnapshot.forEach(doc => {
        const dataItem = { id: doc.id, ...doc.data() } as Client
        clientList.push(dataItem)
      })
      setLoadingList(false)
      setClientList(clientList)
    },
    () => {
      console.log('client - getList() - error')
      setLoadingList(false)
    },
  )
}

interface GetOneProps {
  companyId: string
  clientId: string
}
export const getOne = async ({
  companyId,
  clientId,
}: GetOneProps): Promise<Client | undefined> => {
  const docRef = doc(
    firestore,
    FirebaseCollection.BASE_COMPANY,
    companyId,
    FirebaseCollection.BASE_CLIENT,
    clientId,
  )
  const docSnap = await getDoc(docRef)

  try {
    if (docSnap.exists()) {
      return {
        id: clientId,
        ...docSnap.data(),
      } as Client
    }
  } catch (error) {
    return undefined
  }

  return undefined
}

interface SaveProps {
  companyId: string
  values: Partial<Client>
}
export const save = async ({ companyId, values }: SaveProps) =>
  await addDoc(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_CLIENT,
    ),
    {
      ...values,
      fullnameSearch: `${(values.fullname || '')
        .trimEnd()
        .trimStart()}${new Date().getTime()}`.toUpperCase(),
      createdAt: new Date().getTime(),
      dateCreatedAt: new Date().setHours(0, 0, 0, 0),
      updatedAt: new Date().getTime(),
    },
  ).then(resp => resp.id)

interface updateProps extends FullUpdate {
  companyId: string
  clientId: string
  values: Partial<Client>
}
export const update = async ({
  companyId,
  clientId,
  values,
  isFullUpdate = true,
}: updateProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_CLIENT,
      clientId,
    ),
    {
      ...values,
      ...(isFullUpdate && {
        fullnameSearch: `${(values.fullname || '')
          .trimEnd()
          .trimStart()}${new Date().getTime()}`.toUpperCase(),
      }),
      updatedAt: new Date().getTime(),
    },
    { merge: true },
  )
}

interface RemoveProps {
  companyId: string
  clientId: string
}
export const remove = async ({ companyId, clientId }: RemoveProps) => {
  await deleteDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_CLIENT,
      clientId,
    ),
  )
}

interface RemovePhotoProps {
  companyId: string
  clientId: string
  photoName: string
}
export const removePhoto = async ({
  companyId,
  clientId,
  photoName,
}: RemovePhotoProps) => {
  await deleteFile({
    pathFile: `${getPathFileCompanyClient(companyId, clientId)}/${photoName}`,
  })
}
